import React, { useEffect } from 'react'
import { BookAQuote, CommonHeader, MainTitle } from '../../../components'

const CarpetCleaning: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='border-b-2'>
            <CommonHeader
                title='Carpet Cleaning Services'
                bgImage='assets/images/our-services/carpet-cleaning.jpg'
                mainPath={[
                    { name: 'Home', path: '/' },
                    { name: 'Cleaning Services', path: '/cleaning-services' }
                ]}
            />
            <div className="container mx-auto py-14 space-y-6">
                <MainTitle title='Carpet Cleaning Services by GOBUCKS' />
                <div className="text-gray-700">
                    At GOBUCKS, we know that clean carpets are essential for a healthy and inviting home or business. Our professional carpet cleaning services are designed to remove dirt, stains, and allergens, leaving your carpets looking fresh and extending their lifespan. With GOBUCKS, you can trust that your carpets are in expert hands.
                    <img src="assets/images/our-services/carpet-cleaning.jpg" alt="Carpet Cleaning" className="w-full h-auto mt-5" />
                </div>

                <MainTitle title='Why Choose GOBUCKS for Carpet Cleaning?' />
                <div className="text-gray-700">
                    <ul className="list-disc ml-5 space-y-3">
                        <li>Advanced Cleaning Techniques: We use the latest carpet cleaning technology and techniques to ensure a deep and thorough clean. Our methods effectively remove embedded dirt and stubborn stains without damaging your carpets.</li>
                        <li>Experienced and Skilled Technicians: Our team of highly trained and experienced technicians is dedicated to providing exceptional service. They understand different carpet types and the best cleaning methods for each, ensuring optimal results.</li>
                        <li>Eco-Friendly Products: We care about your health and the environment. Our carpet cleaning products are eco-friendly and non-toxic, providing a safe and healthy environment for your family or employees.</li>
                        <li>Quick Drying Times: Our advanced cleaning process minimizes moisture, ensuring your carpets dry quickly and are ready to use sooner. This reduces the risk of mold and mildew growth.</li>
                        <li>Satisfaction Guaranteed: Your satisfaction is our top priority. If you’re not completely satisfied with our carpet cleaning service, we’ll make it right to ensure you are happy with the results.</li>
                    </ul>
                </div>

                <MainTitle title='Our Carpet Cleaning Services Include:' />
                <div className="text-gray-700">
                    <ul className="list-disc ml-5 space-y-3">
                        <li>Deep Cleaning: Our deep cleaning service removes dirt, dust, and allergens from deep within the carpet fibers, improving indoor air quality and restoring the carpet’s appearance.</li>
                        <li>Stain Removal: We tackle tough stains from spills, pets, and everyday accidents. Our specialized stain removal techniques target and eliminate stains, leaving your carpets looking spotless.</li>
                        <li>Odor Elimination: We use advanced deodorizing techniques to remove unpleasant odors from your carpets, leaving them smelling fresh and clean.</li>
                        <li>Protective Coating: To extend the life of your carpets and protect them from future stains, we offer protective coating services that create a barrier against dirt and spills.</li>
                        <li>Regular Maintenance Plans: We provide regular carpet cleaning maintenance plans tailored to your needs, ensuring your carpets remain clean and well-maintained year-round.</li>
                    </ul>
                </div>

                <MainTitle title='How It Works:' />
                <div className="text-gray-700">
                    <ol className="list-decimal list-inside mb-6 space-y-3">
                        <li>Schedule Your Cleaning: Contact us to book your carpet cleaning service at a convenient time for you.</li>
                        <li>Assessment and Consultation: Our team will assess your carpet’s condition and discuss your specific cleaning needs to create a customized plan.</li>
                        <li>Professional Cleaning: Our skilled technicians will perform a thorough cleaning using advanced equipment and eco-friendly products, ensuring your carpets are deeply cleaned and rejuvenated.</li>
                        <li>Final Inspection: We conduct a final inspection to ensure your carpets meet our high standards of cleanliness and your satisfaction.</li>
                    </ol>
                </div>

                <div className="text-gray-700">
                    Restore the beauty and freshness of your carpets with GOBUCKS’ professional carpet cleaning services. Contact us today to schedule your service and experience the difference of a GOBUCKS clean.
                </div>
                <div className="flex justify-center flex-col items-center space-y-4">
                    <span className="text-gray-700 uppercase font-bold">Send us a message to book your carpet cleaning service today!</span>
                    <BookAQuote bookQuoteType='send-message' serviceType='carpet-cleaning' />
                </div>
            </div>
        </div>
    )
}

export default CarpetCleaning
