import React, { forwardRef, useState, ForwardedRef } from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromHTML } from 'draft-convert';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import clsx from 'clsx';

interface TextEditorProps {
    className?: string;
    onChange: (html: string) => void;
    initialContent?: string;
    id?: string;
    placeholder?: string;
    label?: string;
}

const TextEditor: React.FC<TextEditorProps> = forwardRef<HTMLDivElement, TextEditorProps>((props, ref: ForwardedRef<HTMLDivElement>) => {
    const [editorState, setEditorState] = useState(() => {
        if (props.initialContent) {
            const contentState = convertFromHTML(props.initialContent);
            return EditorState.createWithContent(contentState);
        }
        return EditorState.createEmpty();
    });

    function onEditorStateChange(_editorState: EditorState) {
        setEditorState(_editorState);
        props.onChange(stateToHTML(_editorState.getCurrentContent()));
    }

    return (
        <div
            className={clsx('', props.className)}
            ref={ref}
            id={props.id}
        >
            {props.label && (
                <div className="p-2">
                    <label className="text-black font-normal" htmlFor={props.id}>{props.label}</label>
                </div>
            )}
            <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                placeholder={props.placeholder}
                wrapperClassName="border border-gray-600 bg-white rounded-md overflow-hidden"
                editorClassName="min-h-[300px] border-t border-gray-600 px-2"
                toolbarClassName="border border-gray-600 bg-gray-100"
                toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history'],
                }}
            />
        </div>
    );
});

export default TextEditor;
