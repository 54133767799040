import React, { useEffect } from "react";
import { GET } from "../../auth/AxiosHelper";
import { UserIf } from "../../../types";
import { AdminHeadTitle } from "../../components";

const Users: React.FC = () => {
    const [users, setUsers] = React.useState<UserIf[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const fetchUsers = async () => {
        try {
            setIsLoading(true);
            const res = await GET("/user/get-all-by-is-admin");
            if (res.status === 200) {
                // profileImage: user?.profileImage ? process.env.REACT_APP_API_URL + '/' + user.profileImage : '/assets/images/logo/5871-Gobucks-Logo-03.png'
                const users = res.data.data.map((user: UserIf) => {
                    user.profileImage = user.profileImage ? process.env.REACT_APP_API_URL + '/' + user.profileImage : '/assets/images/logo/5871-Gobucks-Logo-03.png';
                    return user;
                });
                setUsers(users);
                setIsLoading(false);
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="p-4">
            <AdminHeadTitle
                title='Users'
                subtitle='Manage Users'
            />

            {isLoading ?
                <div className="flex gap-3 flex-col justify-center items-center h-[calc(100vh-200px)]">
                    <i className="fas fa-spinner fa-spin fa-2x"></i>
                    <span className="ml-2">Loading...</span>
                </div>
                : users.length > 0 ?
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
                        {users.map((user, index) => (
                            <div key={index} className="bg-white shadow-md rounded-lg p-4 flex flex-col items-center justify-center space-y-4">
                                <div>
                                    <img
                                        src={user.profileImage}
                                        alt={user.name}
                                        className='border-4 border-gray-200 shadow-xl h-[100px] w-[100px] md:w-[160px] md:h-[160px]  rounded-full object-scale-down bg-center'
                                    />
                                </div>
                                <span className="text-gray-500">{user.isAdmin ? "Admin" : "User"}</span>
                                <div className="flex items-center justify-between w-full">
                                    <div className="flex flex-col space-y-2 w-full text-left" >
                                        <div className="flex items-center space-x-2">
                                            <i className="fas fa-user-circle"></i>
                                            <span className="text-gray-500">{user.name}</span>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <div className="flex items-center space-x-2">
                                                <i className="fas fa-envelope"></i>
                                                <span className="text-gray-500">{user.email}</span>
                                            </div>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <div className="flex items-center space-x-2">
                                                <i className="fas fa-user"></i>
                                                <span className="text-gray-500">{user.username}</span>
                                            </div>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <div className="flex items-center space-x-2">
                                                <i className="fas fa-calendar"></i>
                                                <span className="text-gray-500">{new Date(user.createdDate).toDateString()} (created)</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    : users.length === 0 ?
                        <div className="flex gap-3 flex-col justify-center items-center h-[calc(100vh-200px)]">
                            <i className="fas fa-exclamation-triangle fa-2x text-yellow-500"></i>
                            <span className="ml-2">No special offers found</span>
                        </div>
                        : null
            }
        </div >
    );
}

export default Users;