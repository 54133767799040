import React, { useState } from 'react'
import InputField from '../form-fields/inputField';
import { servicesOptionsArray } from '../../data/data';
import { POST } from '../../auth/AxiosHelper';
import { CustomError } from '../../../types';
import { toast } from 'react-toastify';
interface BookAQuoteProps {
    id?: string;
    bookQuoteType: 'book-quote' | 'contact-us' | 'send-message' | 'spacial-offer';
    serviceType?: 'domestic-cleaning' | 'eco-friendly-cleaning' | 'commercial-cleaning' | 'one-off-deep-cleaning' | 'end-of-tenancy-cleaning' | 'carpet-cleaning' | 'oven-cleaning';
    specialOfferId?: string;
    setModalOpen?: (value: boolean) => void;
}

const BookAQuote: React.FC<BookAQuoteProps> = ({ id, bookQuoteType = 'book-quote', serviceType, specialOfferId, setModalOpen }) => {
    const [formData, setFormData] = useState({
        name: "",
        phoneNumber: "",
        address: "",
        selectServices: serviceType ? serviceType : bookQuoteType === 'contact-us' ? 'contact-us' : bookQuoteType === 'spacial-offer' ? 'spacial-offer' : "",
        email: "",
        message: "",
        agree: false,
        sendMessageType: bookQuoteType
    });

    const [errorData, setErrorData] = useState({
        name: "",
        phoneNumber: "",
        selectServices: "",
        email: "",
        message: "",
        agree: ""
    });

    const [isSending, setIsSending] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, type, value, } = e.target;
        const newValue = type === "checkbox" ? !formData[name as keyof typeof formData] : value;
        setFormData({
            ...formData,
            [name]: newValue
        });
    }

    console.log('formData', formData);

    const sendEmail = async () => {
        try {
            if (!formData.agree || !formData.name || !formData.phoneNumber || !formData.email || (bookQuoteType !== 'spacial-offer' && !formData.selectServices)) {
                setErrorData({
                    name: !formData.name ? "Name is required" : "",
                    phoneNumber: !formData.phoneNumber ? "Phone number is required" : "",
                    email: !formData.email ? "Email is required" : "",
                    selectServices: !formData.selectServices ? "Select services is required" : "",
                    message: !formData.message ? "Message is required" : "",
                    agree: !formData.agree ? "You must agree to continue" : ""
                });
                return;
            }


            setIsSending(true);
            const payload = {
                name: formData.name,
                phoneNumber: formData.phoneNumber,
                address: formData.address,
                selectServices: formData.selectServices,
                email: formData.email,
                message: formData.message || null,
                sendMessageType: formData.sendMessageType,
                specialOfferId: specialOfferId,
                agree: formData.agree
            }

            const response = await POST('/mail/send-mail', payload);
            const data = response;
            if (data.status === 200) {
                toast.success(data.data.message);
                setFormData({
                    name: "",
                    phoneNumber: "",
                    address: "",
                    selectServices: serviceType || "",
                    email: "",
                    message: "",
                    agree: false,
                    sendMessageType: bookQuoteType
                });
                setErrorData({
                    name: "",
                    phoneNumber: "",
                    selectServices: "",
                    email: "",
                    message: "",
                    agree: ""
                });
                setIsSending(false);
                if (setModalOpen) {
                    setModalOpen(false);
                }
            }
        } catch (error) {
            setIsSending(false);
            toast.error((error as CustomError).response?.data?.message || (error as CustomError).response?.data?.error || 'Something went wrong');
        }
    }

    return (
        <div className="flex flex-col space-y-5">
            <InputField
                type="text"
                id={`name${id ? `-${id}` : ""}`}
                name="name"
                placeholder="Enter your name"
                className="text-black"
                value={formData.name}
                onChange={handleInputChange}
                required
                error={errorData.name}
            />
            <InputField
                type="text"
                id={`phoneNumber${id ? `-${id}` : ""}`}
                name="phoneNumber"
                placeholder="Enter your phone number"
                className="text-black"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                required
                error={errorData.phoneNumber}
            />
            <InputField
                type="text"
                id={`address${id ? `-${id}` : ""}`}
                name="address"
                placeholder="Enter your address"
                className="text-black"
                value={formData.address}
                onChange={handleInputChange}
            />
            <InputField
                type="text"
                id={`email${id ? `-${id}` : ""}`}
                name="email"
                placeholder="Enter your email"
                className="text-black"
                value={formData.email}
                onChange={handleInputChange}
                required
                error={errorData.email}
            />
            {bookQuoteType !== 'spacial-offer' && (
                <InputField
                    type="select"
                    id={`selectServices${id ? `-${id}` : ""}`}
                    name="selectServices"
                    placeholder="Select services"
                    value={formData.selectServices}
                    options={
                        [
                            { value: "", label: "Select Services" },
                            ...(bookQuoteType === 'contact-us' ? [{ value: "contact-us", label: "Contact Us" }] : []),
                            ...servicesOptionsArray.map(option => ({ value: option.value, label: option.label }))
                        ]
                    }
                    className="text-black"
                    onChange={handleInputChange}
                    required
                    error={errorData.selectServices}
                />
            )}
            {(bookQuoteType === 'contact-us' || bookQuoteType === 'spacial-offer') && (
                <InputField
                    type="textarea"
                    id={`message${id ? `-${id}` : ""}`}
                    name="message"
                    placeholder="Enter your message"
                    className="text-black"
                    value={formData.message}
                    onChange={handleInputChange}
                    rows={bookQuoteType === 'spacial-offer' ? 3 : 5}
                />
            )}
            <InputField
                type="checkbox"
                id={`agree${id ? `-${id}` : ""}`}
                name="agree"
                checked={formData.agree}
                className={`text-xs ${bookQuoteType === 'book-quote' ? 'text-white' : bookQuoteType === 'contact-us' ? 'text-white' : bookQuoteType === 'send-message' ? 'text-black' : 'text-black'}`}
                placeholder="I agree that Gobucks can use my details to contact me in regards to my enquiry and for future promotions"
                onChange={handleInputChange}
                required
                error={errorData.agree}
            />
            <button
                className="bg-secondaryColor text-white py-2 px-4 rounded-md disabled:bg-gray-400 disabled:cursor-not-allowed"
                type="button"
                onClick={() => sendEmail()}
                disabled={
                    isSending ||
                    !formData.agree ||
                    !formData.name ||
                    !formData.phoneNumber ||
                    !formData.email ||
                    (bookQuoteType !== 'spacial-offer' && !formData.selectServices)
                }
            >
                {isSending ?
                    <div className="flex items-center justify-center">
                        <svg className="animate-spin h-5 w-5 mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0c4.418 0 8 3.582 8 8"></path>
                        </svg>
                        <span>Sending...</span>
                    </div>
                    :
                    <div>
                        Send Message
                    </div>
                }
            </button>
        </div>
    )
}

export default BookAQuote