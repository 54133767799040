import React from 'react'
import CleaningCard from '../card/cleaning-card'

const GetInTouch: React.FC = () => {

    const services = [
        {
            title: 'Spacial Offers',
            description: 'SPACIAL OFFERS',
            image: 'assets/images/our-services/special-offers.jpg',
            link: '/special-offers'
        },
        {
            title: 'Cleaning Services',
            description: 'CLEANING SERVICES',
            image: 'assets/images/our-services/cleaning-services.jpg',
            link: '/cleaning-services'

        },
        {
            title: 'Contact Us',
            description: 'CONTACT US',
            image: 'assets/images/our-services/contact-us.jpg',
            link: '/contact-us'
        }
    ]
    return (
        <div className='bg-bgGray'>
            <div className='container mx-auto py-14'>
                <div className='uppercase text-primaryColor font-bold text-center text-2xl'>
                    Get In Touch
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4'>
                    {services.map((service, index) => (
                        <CleaningCard key={index} data={service} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default GetInTouch
