export type NavigationItem = {
    title: string;
    path?: string;
    icon?: string;
    type?: string;
    children?: Array<GroupItem | ChildItem>;
};

export interface ChildItem {
    title: string;
    path: string;
    icon: string;
    type: string;
    children?: ChildItem[];
}

export interface GroupItem {
    title: string;
    icon: string;
    path?: string;
    type: string;
    children: ChildItem[];
}


// HOME
// ABOUT
// SPECIAL OFFERS 
// CLEANING SERVICES 
// PRICES
// CONTACT US


// cleaning services

// Domestic Cleaning
// DOMESTIC CLEANING

// Eco Friendly Cleaning Services
// ECO-FRIENDLY CLEANING

// Commercial Cleaning
// COMMERCIAL CLEANING


// One Off Deep Cleaning
// ONE-OFF DEEP CLEANING

// End Of Tenancy Cleaning Services
// MOVE IN MOVE OUT

// Carpet Cleaning Services
// CARPET CLEANING

// Oven Cleaning
// OVEN CLEANING

const navigationConfig: NavigationItem[] = [
    {
        title: 'Home',
        path: '/',
        icon: 'home',
        type: 'link'
    },
    {
        title: 'About',
        path: '/about',
        icon: 'info',
        type: 'link'
    },
    {
        title: 'Special Offers',
        path: '/special-offers',
        icon: 'star',
        type: 'link'
    },
    {
        title: 'Cleaning Services',
        path: '/cleaning-services',
        icon: 'cogs',
        type: 'group',
        children: [
            {
                title: 'Domestic Cleaning',
                path: '/cleaning-services/domestic-cleaning',
                icon: 'cogs',
                type: 'link'
            },
            {
                title: 'Eco Friendly Cleaning Services',
                path: '/cleaning-services/eco-friendly-cleaning',
                icon: 'cogs',
                type: 'link'
            },
            {
                title: 'Commercial Cleaning',
                path: '/cleaning-services/commercial-cleaning',
                icon: 'cogs',
                type: 'link'
            },
            {
                title: 'One Off Deep Cleaning',
                path: '/cleaning-services/one-off-deep-cleaning',
                icon: 'cogs',
                type: 'link'
            },
            {
                title: 'End Of Tenancy Cleaning Services',
                path: '/cleaning-services/end-of-tenancy-cleaning',
                icon: 'cogs',
                type: 'link'
            },
            {
                title: 'Carpet Cleaning Services',
                path: '/cleaning-services/carpet-cleaning',
                icon: 'cogs',
                type: 'link'
            },
            {
                title: 'Oven Cleaning',
                path: '/cleaning-services/oven-cleaning',
                icon: 'cogs',
                type: 'link'
            }
        ]
    },
    {
        title: 'Prices',
        path: '/prices',
        icon: 'dollar',
        type: 'link'
    },
    {
        title: 'Contact Us',
        path: '/contact-us',
        icon: 'phone',
        type: 'link'
    }
    // {
    //     title: 'Services',
    //     icon: 'cogs',
    //     type: 'group',
    //     children: [
    //         {
    //             title: 'JAPANESE LANGUAGE CLASSES',
    //             icon: 'cogs',
    //             type: 'group',
    //             path: '/japanese-language-classes',
    //             children: [
    //                 {
    //                     title: 'Full Time Course',
    //                     path: '/japanese-language-classes/full-time-course',
    //                     icon: 'cogs',
    //                     type: 'link'
    //                 },
    //                 {
    //                     title: 'Part Time Course',
    //                     path: '/japanese-language-classes/part-time-course',
    //                     icon: 'cogs',
    //                     type: 'link'
    //                 },
    //                 {
    //                     title: 'Advance Course',
    //                     path: '/japanese-language-classes/advance-course',
    //                     icon: 'cogs',
    //                     type: 'link'
    //                 }
    //             ],
    //         },
    //         {
    //             title: 'CONSULTATION',
    //             path: '/consultation',
    //             icon: 'cogs',
    //             type: 'link'
    //         },
    //         {
    //             title: 'MASTER’S PROGRAM',
    //             path: '/masters-program',
    //             icon: 'cogs',
    //             type: 'link'
    //         }
    //     ]
    // },
    // {
    //     title: 'Events',
    //     path: '/events',
    //     icon: 'calendar',
    //     children: [
    //         {
    //             title: 'Upcoming Events',
    //             path: '/events/upcoming-events',
    //             icon: 'calendar',
    //             type: 'link'
    //         },
    //         {
    //             title: 'Past Events',
    //             path: '/events/past-events',
    //             icon: 'calendar',
    //             type: 'link'
    //         }
    //     ]

    // },
    // {
    //     title: 'Gallery',
    //     path: '/gallery',
    //     icon: 'image',
    //     type: 'link'
    // },
    // {
    //     title: 'About',
    //     path: '/about',
    //     icon: 'info',
    //     type: 'link'
    // },
    // {
    //     title: 'Contact',
    //     path: '/contact-us',
    //     icon: 'phone',
    //     type: 'link'
    // },
]

export default navigationConfig;