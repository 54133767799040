import React, { useEffect } from 'react'
import { CommonHeader, SpecialOfferCard } from '../../components'
import { useAppContext } from '../../../contexts/AppContext';

const SpecialOffers: React.FC = () => {
    const { specialOffers } = useAppContext()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div>
            <CommonHeader
                title='Special Offers'
                bgImage='assets/images/special-offers/oven-cleaning.jpg'
                mainPath={[{ name: 'Home', path: '/' }]}
            />
            <div className='container mx-auto py-14 '>
                {specialOffers && specialOffers.length > 0 ? (
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10'>
                        <SpecialOfferCard data={specialOffers} />
                    </div>
                ) : specialOffers && specialOffers.length === 0 ? (
                    <div className='flex flex-col items-center justify-center min-h-[calc(100vh-20rem)]'>
                        <img
                            src='assets/images/special-offers/no-special-offers.png'
                            alt='No Special Offers'
                            className='mx-auto h-64 w-64'
                        />
                        <h1 className='text-center text-2xl mt-5'>No Special Offers Available</h1>
                    </div>
                ) : (
                    <h1 className='text-center text-2xl'>Loading...</h1>
                )}
            </div>

        </div>
    )
}

export default SpecialOffers