import React, { useState } from 'react'
import { SpecialOfferIf } from '../../../../types';
import Modal from '../../modal';
import BookAQuote from '../../book-a-quote';


interface SpecialOfferCardProps {
    data: SpecialOfferIf[];
    isSlice?: boolean;
    start?: number;
    end?: number;
    isAdmin?: boolean;
    handleModalOpen?: (type: string, data: SpecialOfferIf) => void;
}
const SpecialOfferCard: React.FC<SpecialOfferCardProps> = ({ data, isSlice, start = 0, end, isAdmin = false, handleModalOpen }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [specialOfferId, setSpecialOfferId] = useState<string | null>(null);

    const ModalOpen = (id: string) => {
        setModalOpen(true);
        setSpecialOfferId(id);
    }

    const ModalClose = () => {
        setModalOpen(false);
    }

    const getModalContent = () => {
        return (
            <div className="flex flex-col items-center space-y-4">
                <span className="text-gray-700 uppercase font-bold">Instant Callback Request</span>
                <span className="text-gray-700">Complete the form below and one of our representatives will give you a call as soon as possible to arrange the service.</span>
                <BookAQuote bookQuoteType='spacial-offer' specialOfferId={String(specialOfferId)} setModalOpen={setModalOpen} />
            </div>
        )
    }

    return (
        <>
            {(isSlice ? data?.slice(start, end) : data)?.map((item, index) => {
                return (
                    <div className='flex flex-col justify-between border border-bgGray pb-3 bg-white rounded-lg  relative'
                        key={index}
                    >
                        {isAdmin &&
                            <div className='absolute top-0 right-0 bg-primaryColor text-white text-xs font-bold px-3 py-2 rounded-bl-md rounded-tr-md'>
                                {item.order}
                            </div>
                        }
                        <div>
                            {item?.title &&
                                <div className='bg-bgGray rounded-t-md py-3 flex items-center justify-center'>
                                    <h1 className='text-black text-center uppercase text-lg font-bold px-2'>{item.title}</h1>
                                </div>
                            }
                            {item.price &&
                                <div className='border-b border-bgGray py-3 flex items-center justify-center'>
                                    <span className='text-primaryColor text-2xl md:text-5xl'>
                                        {
                                            new Intl.NumberFormat('en-GB', {
                                                style: 'currency',
                                                currency: 'GBP',
                                            }).format(item.price).replace('.00', '')
                                        }
                                    </span>
                                </div>
                            }
                            <div className='p-5'>
                                {item.image &&
                                    <div className='h-36'>
                                        <img src={item.image} alt={item.title} className='w-full h-full object-cover' />
                                    </div>
                                }
                                {item.description &&
                                    <div className='text-xs text-gray-600 mt-5 text-justify tracking-wide leading-[1.1rem]'>
                                        <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                    </div>
                                }
                            </div>
                        </div>
                        {!isAdmin &&
                            <div className='flex justify-end -mr-3 cursor-pointer hover:translate-x-2 transform transition-all duration-300 shadow-sm shadow-white rounded-md'>
                                <div
                                    className="card-price text-xs text-white font-bold"
                                    onClick={() => ModalOpen(String(item._id))}
                                >
                                    Enquire Now
                                </div>
                            </div>
                        }
                        {isAdmin &&
                            <div className='flex justify-between px-5'>
                                <div
                                    className="bg-primaryColor px-5 text-xs hover:text-secondaryColor text-white font-bold py-3 rounded-md cursor-pointer hover:bg-primaryColorDark transform transition-all duration-300"
                                    onClick={() => handleModalOpen && handleModalOpen('edit', item)}
                                >
                                    Edit
                                </div>
                                <div
                                    className="bg-red-500 text-xs px-5 hover:text-secondaryColor text-white font-bold py-3 rounded-md cursor-pointer hover:bg-red-600 transform transition-all duration-300"
                                    onClick={() => handleModalOpen && handleModalOpen('delete', item)}
                                >
                                    Delete
                                </div>

                            </div>
                        }
                    </div>
                )
            })}
            {isModalOpen &&
                <Modal
                    size='sm'
                    content={getModalContent()}
                    onClose={ModalClose}
                    onConfirm={ModalClose}
                    confirmButtonClass="bg-primaryColor"
                    height='xl'
                />
            }
        </>
    )
}

export default SpecialOfferCard
