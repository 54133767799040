import React, { useEffect } from 'react'
import { BookAQuote, CommonHeader, MainTitle } from '../../../components'

const EcoFriendlyCleaning: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <div className='border-b-2'>
            <CommonHeader
                title='Eco-Friendly Cleaning'
                bgImage='assets/images/our-services/eco-friendly-cleaning.jpg'
                mainPath={[
                    { name: 'Home', path: '/' },
                    { name: 'Cleaning Services', path: '/cleaning-services' }
                ]}
            />
            <div className="container mx-auto py-14 space-y-6">
                <MainTitle title='Eco-Friendly Cleaning Services by GOBUCKS' />
                <div className="text-gray-700">
                    At GOBUCKS, we believe that a clean home shouldn’t come at the expense of the environment. That’s why we are proud to offer eco-friendly cleaning services that prioritize the health of your home, your family, and our planet. Our commitment to sustainability means you can enjoy a pristine living space while supporting green practices.
                    <img src="assets/images/our-services/eco-friendly-cleaning.jpg" alt="Eco-Friendly Cleaning" className="w-full h-auto mt-5" />
                </div>

                <MainTitle title='Why Choose GOBUCKS for Eco-Friendly Cleaning?' />
                <div className="text-gray-700">
                    <ul className="list-disc ml-5 space-y-3">
                        <li>Green Cleaning Products: We use non-toxic, biodegradable cleaning products that are safe for your family and pets. Our green cleaning solutions effectively eliminate dirt and germs without leaving harmful residues behind.</li>
                        <li>Sustainable Practices: Our cleaning methods are designed to minimize environmental impact. From reducing waste to conserving water, we implement eco-conscious practices in every aspect of our service.</li>
                        <li>Healthier Home Environment: Eco-friendly cleaning reduces exposure to harsh chemicals, creating a healthier indoor environment. This is especially beneficial for households with children, pets, or individuals with allergies or sensitivities.</li>
                        <li>Professional and Trained Staff: Our cleaning professionals are trained in eco-friendly cleaning techniques and are dedicated to delivering exceptional results while adhering to sustainable practices.</li>
                        <li>Customized Green Cleaning Plans: We understand that every home is unique. Our team works with you to develop a personalized green cleaning plan that fits your needs and ensures your home remains spotless and environmentally friendly.</li>
                    </ul>
                </div>

                <MainTitle title='Our Eco-Friendly Cleaning Services Include:' />
                <div className="text-gray-700">
                    <ul className="list-disc ml-5 space-y-3">
                        <li>General Cleaning: Routine cleaning tasks using eco-friendly products, including dusting, vacuuming, mopping, and wiping down surfaces to maintain a clean and healthy home.</li>
                        <li>Deep Cleaning: A comprehensive clean that reaches every corner of your home, using green products and methods to tackle tough dirt and grime without harming the environment.</li>
                        <li>Kitchen Cleaning: Detailed cleaning of all kitchen surfaces, appliances, and cabinets with non-toxic products to ensure a safe and hygienic cooking space.</li>
                        <li>Bathroom Cleaning: Thorough cleaning and sanitization of bathrooms using eco-friendly cleaners, ensuring a fresh and germ-free environment.</li>
                        <li>Specialized Services: Green cleaning for carpets, windows, upholstery, and more, using environmentally safe techniques and products.</li>
                    </ul>
                </div>

                <MainTitle title='How It Works:' />
                <div className="text-gray-700">
                    <ol className="list-decimal list-inside mb-6 space-y-3">
                        <li>Book Your Service: Contact us to schedule your eco-friendly cleaning service at a time that suits you.</li>
                        <li>Consultation and Planning: Our team will discuss your specific cleaning needs and develop a tailored green cleaning plan.</li>
                        <li>Professional Cleaning: Our trained cleaners will arrive on time and perform the tasks efficiently and thoroughly, using eco-friendly products and methods.</li>
                        <li>Quality Check: We ensure everything is cleaned to your satisfaction, with the added benefit of knowing your home was cleaned sustainably.</li>
                    </ol>
                </div>

                <div className="text-gray-700">
                    Join the GOBUCKS green revolution and enjoy a cleaner, healthier home while protecting the planet. Contact us today to schedule your eco-friendly cleaning service and see why so many homeowners trust GOBUCKS for their sustainable cleaning needs.
                </div>
                <div className="flex justify-center flex-col items-center space-y-4">
                    <span className="text-gray-700 uppercase font-bold">Send us a message to book your eco-friendly cleaning service today!</span>
                    <BookAQuote bookQuoteType='send-message' serviceType='eco-friendly-cleaning' />
                </div>
            </div>
        </div>
    )
}

export default EcoFriendlyCleaning
