import React, { useEffect } from 'react'
import { CleaningCard, CommonHeader } from '../../components'
import { services } from '../home/widgets/our-services'

const CleaningServices: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='border-b-2'>
            <CommonHeader
                title='Cleaning Services'
                bgImage='assets/images/our-services/oven-cleaning.jpg'
                mainPath={[{ name: 'Home', path: '/' }]}
            />
            <div className='container mx-auto py-14'>
                <div className='uppercase text-primaryColor font-bold text-center text-2xl mb-5'>
                    CLEANING SERVICES IN AYLESBURY
                </div>
                <div className='text-left text-lg text-gray-500 my-5'>
                    Trustworthy Cleaning Services Tailored to Your Needs
                </div>

                <div className='text-left text-gray-500 my-5 space-y-5 text-sm'>
                    <p>
                        Gobucks is your go-to cleaning service provider offering a wide array of commercial and residential cleaning solutions. Serving Aylesbury and the Home Counties, we are committed to delivering top-notch cleaning services that meet your unique requirements.
                    </p>
                    <p>
                        Explore our services below for detailed information.
                    </p>
                    <p>
                        We also offer emergency cleaning services, available 7 days a week.
                    </p>
                    <p>
                        For more information about our professional cleaning services in Aylesbury, contact Gobucks at 076 85 63051.
                        Request a free quote or conveniently book online today!
                    </p>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
                    {services.map((service, index) => (
                        <CleaningCard key={index} data={service} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CleaningServices
