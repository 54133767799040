import React from "react";
import { NavigationItem } from "../../routes/navigationConfig";
import { Link } from "react-router-dom";
import BookAQuote from "../book-a-quote";

interface FooterProps {
    navigation: NavigationItem[];
    bookAQuoteRef: React.RefObject<HTMLDivElement>;
}

const Footer: React.FC<FooterProps> = ({ navigation, bookAQuoteRef }) => {
    return (
        <footer>
            <div className="bg-primaryColor text-white">
                <div className="container mx-auto py-6 space-y-8">
                    <div className="flex w-full flex-col items-center justify-center">
                        <img
                            className="h-[30px] my-2"
                            src="assets/images/logo/5871-Gobucks-Logo-04.png"
                            alt="go bucks logo footer"
                        />
                    </div>
                    <div className="leanerGrade-yellow h-2 " />
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-10 md:gap-5 lg:gap-10">
                        <div className="w-full flex flex-col items-start gap-5">
                            <div className="flex flex-col gap-1 w-full">
                                <h3 className="text-sm font-medium uppercase">Navigation</h3>
                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center">
                                        <div className="h-[0.25rem] z-10 w-10 bg-secondaryColor rounded-md"></div>
                                    </div>
                                    <div className="absolute inset-0 flex items-center">
                                        <div className="h-0.5 w-full bg-white rounded-md"></div>
                                    </div>
                                </div>
                            </div>
                            <ul className="list-reset capitalize flex flex-col items-start gap-5">
                                {navigation.length > 0 && navigation.map((item, index) => (
                                    <li key={index}>
                                        <Link
                                            to={String(item.path)}
                                            className="text-white hover:text-secondaryColor flex items-center"
                                        >
                                            <i className="fas fa-angle-right"></i> &nbsp;
                                            {item.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="w-full flex flex-col items-start gap-5">
                            <div className="flex flex-col gap-1 w-full">
                                <h3 className="text-sm font-medium uppercase">Contact Us</h3>
                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center">
                                        <div className="h-[0.25rem] z-10 w-10 bg-secondaryColor rounded-md"></div>
                                    </div>
                                    <div className="absolute inset-0 flex items-center">
                                        <div className="h-0.5 w-full bg-white rounded-md"></div>
                                    </div>
                                </div>
                            </div>
                            <ul className="list-reset flex flex-col items-start gap-5">
                                <li>
                                    <a
                                        href="tel:07897969232"
                                        className="text-white hover:text-secondaryColor flex items-center"
                                    >
                                        <i className="fas fa-phone-alt"></i> &nbsp;
                                        0789 796 92 32
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="mailto:info@gobucks.co.uk"
                                        className="text-white hover:text-secondaryColor flex items-center"
                                    >
                                        <i className="fas fa-envelope"></i> &nbsp;
                                        info@gobucks.co.uk
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="w-full flex flex-col items-start gap-5">
                            <div className="flex flex-col gap-1 w-full">
                                <h3 className="text-sm font-medium uppercase">Follow Us</h3>
                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center">
                                        <div className="h-[0.25rem] z-10 w-10 bg-secondaryColor rounded-md"></div>
                                    </div>
                                    <div className="absolute inset-0 flex items-center">
                                        <div className="h-0.5 w-full bg-white rounded-md"></div>
                                    </div>
                                </div>
                            </div>
                            <ul className="list-reset capitalize flex flex-col items-start gap-5">
                                <li>
                                    <a
                                        href="https://www.facebook.com/"
                                        target="_blank"
                                        className="text-white hover:text-secondaryColor flex items-center"
                                        rel="noreferrer"
                                    >
                                        <i className="fab fa-facebook-f"></i> &nbsp;
                                        Facebook
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.instagram.com/"
                                        target="_blank"
                                        className="text-white hover:text-secondaryColor flex items-center"
                                        rel="noreferrer"
                                    >
                                        <i className="fab fa-instagram"></i> &nbsp;
                                        Instagram
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://twitter.com/"
                                        target="_blank"
                                        className="text-white hover:text-secondaryColor flex items-center"
                                        rel="noreferrer"
                                    >
                                        <i className="fab fa-twitter"></i> &nbsp;
                                        Twitter
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="w-full flex flex-col items-start gap-5">
                            <div className="flex flex-col gap-1 w-full">
                                <h3 className="text-sm font-medium uppercase">Get A Free Quote</h3>
                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center">
                                        <div className="h-[0.25rem] z-10 w-10 bg-secondaryColor rounded-md"></div>
                                    </div>
                                    <div className="absolute inset-0 flex items-center">
                                        <div className="h-0.5 w-full bg-white rounded-md"></div>
                                    </div>
                                </div>
                            </div>
                            <div ref={bookAQuoteRef}>
                                <BookAQuote id="footer" bookQuoteType="book-quote" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full text-xs flex text-white flex-col md:flex-row justify-center bg-black py-4">
                <p className="text-center">
                    © Copyright 2024 | Gobucks | All Rights Reserved |
                </p>
                <p className="text-center pl-2">
                    Developed By
                    <a
                        href="https://pramudnadula.com/"
                        target="_blank"
                        className="pl-2 text-blue-500 hover:text-secondaryColor"
                        rel="noreferrer"
                    >
                        Pramud Nadula
                    </a>
                </p>
            </div>
        </footer>
    );
};

export default Footer;
