import React from 'react';

const OurLocation: React.FC = () => {
    return (
        <div>
            <div className="container mx-auto py-14">
                <div className="uppercase text-primaryColor font-bold text-center text-2xl pb-5">
                    Our Location
                </div>
                <div className="w-full">
                    <iframe
                        title="Google Maps"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2465.9952129946932!2d-0.8429027120477711!3d51.824517915011214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876f73e24cfb79f%3A0x403e83585329fe99!2sWillow%20Court%2C%2019%20Intalbury%20Ave%2C%20Aylesbury%20HP19%208HE%2C%20UK!5e0!3m2!1sen!2slk!4v1718863879001!5m2!1sen!2slk"
                        width="100%"
                        height="400"
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    />
                </div>
            </div>
        </div>
    );
};

export default OurLocation;
