import React, { useEffect } from 'react'
import { BookAQuote, CommonHeader, MainTitle } from '../../../components'

const OneOffDeepCleaning: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='border-b-2'>
            <CommonHeader
                title='One-Off Deep Cleaning'
                bgImage='assets/images/our-services/one-off-deep-cleaning.jpg'
                mainPath={[
                    { name: 'Home', path: '/' },
                    { name: 'Cleaning Services', path: '/cleaning-services' }
                ]}
            />
            <div className="container mx-auto py-14 space-y-6">
                <MainTitle title='One-Off Deep Cleaning Services by GOBUCKS' />
                <div className="text-gray-700">
                    At GOBUCKS, we understand that sometimes your home or business needs more than just a regular clean. Our one-off deep cleaning services are designed to give your space a thorough, top-to-bottom clean, leaving it spotless and refreshed. Whether you’re preparing for a special event, moving in or out, or just need a deep clean to reset your space, GOBUCKS has you covered.
                    <img src="assets/images/our-services/one-off-deep-cleaning.jpg" alt="One-Off Deep Cleaning" className="w-full h-auto mt-5" />
                </div>

                <MainTitle title='Why Choose GOBUCKS for One-Off Deep Cleaning?' />
                <div className="text-gray-700">
                    <ul className="list-disc ml-5 space-y-3">
                        <li>Thorough and Detailed Cleaning: Our deep cleaning service goes beyond the surface to tackle dirt, grime, and hidden dust. We clean every nook and cranny, ensuring your space is immaculately clean.</li>
                        <li>Experienced and Professional Staff: Our team of cleaning experts is trained, experienced, and dedicated to delivering the highest quality service. They use professional techniques and equipment to achieve outstanding results.</li>
                        <li>High-Quality Cleaning Products: We use powerful, eco-friendly cleaning products that are effective at removing dirt and stains while being safe for your family and pets.</li>
                        <li>Customized Cleaning Plans: We tailor our deep cleaning services to meet your specific needs and preferences. Whether it’s a particular area that needs extra attention or specific cleaning requirements, we customize our approach to ensure your complete satisfaction.</li>
                        <li>Satisfaction Guaranteed: We stand by the quality of our work. If you’re not completely satisfied with our deep cleaning service, we’ll make it right.</li>
                    </ul>
                </div>

                <MainTitle title='Our One-Off Deep Cleaning Services Include:' />
                <div className="text-gray-700">
                    <ul className="list-disc ml-5 space-y-3">
                        <li>Living Areas: Thorough cleaning of living rooms, dining rooms, and other common areas, including dusting, vacuuming, and mopping floors, cleaning windows, and wiping down all surfaces.</li>
                        <li>Kitchen Cleaning: Detailed cleaning of kitchen appliances, countertops, cabinets, and floors. We also clean and sanitize sinks, and ensure all kitchen surfaces are spotless.</li>
                        <li>Bathroom Cleaning: Comprehensive cleaning and sanitization of bathrooms, including toilets, showers, bathtubs, sinks, and mirrors. We also scrub tiles and grout to remove any build-up.</li>
                        <li>Bedroom Cleaning: Deep cleaning of bedrooms, including dusting, vacuuming, and mopping floors, cleaning under furniture, and organizing closets and storage areas.</li>
                        <li>Specialty Cleaning Tasks: Additional services such as carpet cleaning, upholstery cleaning, and window washing are available to address specific needs and ensure every part of your space is pristine.</li>
                    </ul>
                </div>

                <MainTitle title='How It Works:' />
                <div className="text-gray-700">
                    <ol className="list-decimal list-inside mb-6 space-y-3">
                        <li>Schedule Your Cleaning: Contact us to book your one-off deep cleaning service at a time that suits you.</li>
                        <li>Consultation and Assessment: Our team will discuss your specific cleaning needs and conduct an assessment to create a customized cleaning plan.</li>
                        <li>Professional Deep Cleaning: Our experienced cleaners will arrive on time and perform a thorough, detailed cleaning of your space, using professional techniques and equipment.</li>
                        <li>Final Inspection: We ensure every area is cleaned to your satisfaction, with a final inspection to confirm the highest standards of cleanliness.</li>
                    </ol>
                </div>

                <div className="text-gray-700">
                    Experience the transformative power of a GOBUCKS deep clean and enjoy a refreshed, immaculate space. Contact us today to schedule your one-off deep cleaning service and see why so many clients trust GOBUCKS for their cleaning needs.
                </div>
                <div className="flex justify-center flex-col items-center space-y-4">
                    <span className="text-gray-700 uppercase font-bold">Send us a message to book your one-off deep cleaning service today!</span>
                    <BookAQuote bookQuoteType='send-message' serviceType='one-off-deep-cleaning' />
                </div>
            </div>
        </div>
    )
}

export default OneOffDeepCleaning
