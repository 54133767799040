import React, { useEffect, useState } from 'react'
import { AdminHeadTitle, InputField, Modal } from '../../components'
import { CustomError, SpecialOfferIf } from '../../../types';
import { DELETE, GET, POST, PUT } from '../../auth/AxiosHelper';
import { toast } from 'react-toastify';
import ViewSpecialOffers from './view-special-offers';
import TextEditor from '../../components/editor';

const AdminSpecialOffers: React.FC = () => {
    const [specialOffers, setSpecialOffers] = useState<SpecialOfferIf[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState<string | null>(null);
    const [currentOffer, setCurrentOffer] = useState<SpecialOfferIf | null>(null);

    const [title, setTitle] = useState('');
    const [price, setPrice] = useState<number | null>(null);
    const [description, setDescription] = useState('');
    const [image, setImage] = useState<File | null>(null);
    const [order, setOrder] = useState<number | null>(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
    const [inputKey, setInputKey] = useState<number>(Date.now());

    const [deleteConfirm, setDeleteConfirm] = useState('');

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];
            const valid = file?.size <= 1024 * 1024 * 2;
            if (!valid) {
                toast.error('File size exceeds the limit of 2MB');
                return;
            }
            setImage(file);
        }
    }

    const handleRemoveImage = () => {
        if (imagePreviewUrl) {
            URL.revokeObjectURL(imagePreviewUrl);
        }
        setImage(null);
        setImagePreviewUrl(null);
        setInputKey(Date.now()); // Change the key to force re-render
    }

    useEffect(() => {
        if (image) {
            const url = URL.createObjectURL(image);
            setImagePreviewUrl(url);

            return () => {
                URL.revokeObjectURL(url);
            }
        }
    }, [image]);

    const fetchSpecialOffers = async () => {
        try {
            setLoading(true);
            const response = await GET('/special-offers/all');
            if (response.status === 200) {
                const data = response.data.data.map((item: SpecialOfferIf) => ({
                    ...item,
                    image: process.env.REACT_APP_API_URL + '/' + item.image
                }));
                setSpecialOffers(data);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    const handleModalOpen = (type: string, data: SpecialOfferIf) => {
        setCurrentOffer(data);
        setModalType(type);
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setCurrentOffer(null);
        setModalType(null);
        handleRemoveImage();
    }

    const handleAddSave = async () => {
        try {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('price', price ? price.toString() : '');
            formData.append('description', description);
            formData.append('image', image ? image : '');
            formData.append('order', order ? order.toString() : '');

            const response = await POST('/special-offers/upload', formData, { contentType: 'multipart/form-data' });
            if (response.status === 201) {
                toast.success('Special offer added successfully');
                fetchSpecialOffers();
                handleModalClose();
                setTitle('');
                setPrice(null);
                setDescription('');
                setImage(null);
                setOrder(null);
            }
        } catch (error) {
            console.error(error);
            toast.error((error as CustomError).response?.data?.message);
        }
    }

    const handleEditSave = async () => {
        try {
            const response = await PUT(`/special-offers/update/${currentOffer?._id}`, currentOffer);
            if (response.status === 200) {
                toast.success('Special offer updated successfully');
                fetchSpecialOffers();
                handleModalClose();
            }
        } catch (error) {
            console.error(error);
            toast.error('Failed to update special offer');
        }
    }

    const handleDeleteConfirm = async () => {
        try {
            const response = await DELETE(`/special-offers/delete/${currentOffer?._id}`);
            if (response.status === 200) {
                toast.success('Special offer deleted successfully');
                fetchSpecialOffers();
                setDeleteConfirm('');
                handleModalClose();
            }
        } catch (error) {
            console.error(error);
            toast.error('Failed to delete special offer');
        }
    }

    useEffect(() => {
        fetchSpecialOffers();
    }, []);

    const getModalContent = () => {
        if (modalType === 'add') {
            return (
                <div className='space-y-5'>
                    <InputField
                        type='text'
                        id='title'
                        name='title'
                        placeholder='Enter cleaning offer title'
                        value={title}
                        onChange={(e) =>
                            setTitle(e.target.value.toUpperCase())
                        }
                    />
                    <InputField
                        type='number'
                        id='price'
                        name='price'
                        placeholder='Enter cleaning offer price'
                        value={price}
                        onChange={(e) => setPrice(parseFloat(e.target.value))}
                    />
                    {imagePreviewUrl && (
                        <div className='relative w-full h-[200px] lg:h-[300px] rounded-lg overflow-hidden'>
                            <button
                                className=" absolute top-2 right-2 text-white bg-red-500 rounded-full p-1 cursor-pointer w-[30px]"
                                onClick={handleRemoveImage}
                            >
                                <i className="fas fa-times"></i>
                            </button>
                            <img
                                src={imagePreviewUrl}
                                alt=""
                                className='w-full h-full object-cover'
                            />
                        </div>
                    )}
                    <div className="mb-4">
                        <label
                            htmlFor="file"
                            className="flex cursor-pointer items-center whitespace-nowrap justify-center bg-primaryColor text-white rounded-md hover:opacity-80 transition-all duration-300 ease-in-out gap-10 px-4 py-2 w-full"
                        >
                            <i className="fas fa-upload"></i>Choose Images
                        </label>
                        <input
                            key={inputKey} // Add key to force re-render
                            type="file"
                            id="file"
                            onChange={handleImageChange}
                            accept="image/*"
                            className="hidden"
                        />
                    </div>
                    <InputField
                        type='number'
                        id='order'
                        name='order'
                        placeholder='Enter cleaning offer order'
                        value={order}
                        onChange={(e) => setOrder(parseFloat(e.target.value))}
                        min={0}
                    />
                    <TextEditor
                        // label='Description'
                        id='description'
                        onChange={setDescription}
                        placeholder='Enter cleaning offer description'
                    />
                </div>
            );
        }

        if (modalType === 'edit' && currentOffer) {
            return (
                <div className='space-y-5'>
                    <InputField
                        label='Title'
                        type='text'
                        id='title'
                        name='title'
                        placeholder='Enter cleaning offer title'
                        value={currentOffer.title}
                        onChange={(e) => setCurrentOffer({ ...currentOffer, title: e.target.value })}
                    />
                    <InputField
                        label='Price'
                        type='number'
                        id='price'
                        name='price'
                        placeholder='Enter cleaning offer price'
                        value={String(currentOffer.price)}
                        onChange={(e) => setCurrentOffer({ ...currentOffer, price: parseFloat(e.target.value) })}
                    />
                    <InputField
                        label='Order'
                        type='number'
                        id='order'
                        name='order'
                        placeholder='Enter cleaning offer order'
                        value={String(currentOffer.order)}
                        onChange={(e) => setCurrentOffer({ ...currentOffer, order: parseFloat(e.target.value) })}
                        min={0}
                    />
                    <TextEditor
                        label='Description'
                        onChange={(e) => setCurrentOffer({ ...currentOffer, description: e })}
                        initialContent={currentOffer.description}
                    />
                </div>
            );
        }

        if (modalType === 'delete' && currentOffer) {
            return <div className='space-y-5'>
                <p>Are you sure you want to delete the offer {`"${currentOffer.title}"`}?</p>
                <InputField
                    type='text'
                    id='deleteConfirm'
                    name='deleteConfirm'
                    placeholder={`Type "${currentOffer.title}" to confirm delete`}
                    value={deleteConfirm}
                    onChange={(e) => setDeleteConfirm(e.target.value)}
                />
            </div>;
        }

        return null;
    }

    return (
        <div className='p-4'>
            <AdminHeadTitle
                title='Special Offers'
                subtitle='Manage special offers for your customers.'
                content={
                    <button
                        onClick={() => handleModalOpen('add', {} as SpecialOfferIf)}
                        className='px-4 py-2 w-full bg-primaryColor hover:bg-secondaryColor text-white rounded'
                    >
                        Add Special Offer
                    </button>}
            />
            {isLoading ?
                <div className="flex gap-3 flex-col justify-center items-center h-[calc(100vh-200px)]">
                    <i className="fas fa-spinner fa-spin fa-2x"></i>
                    <span className="ml-2">Loading...</span>
                </div>
                : specialOffers.length > 0 ?
                    <ViewSpecialOffers specialOffers={specialOffers} handleModalOpen={handleModalOpen} />
                    : specialOffers.length === 0 ?
                        <div className="flex gap-3 flex-col justify-center items-center h-[calc(100vh-200px)]">
                            <i className="fas fa-exclamation-triangle fa-2x text-yellow-500"></i>
                            <span className="ml-2">No special offers found</span>
                        </div>
                        : null
            }

            {isModalOpen && (
                <Modal
                    title={modalType === 'add' ? 'Add Special Offer' : modalType === 'edit' ? 'Edit Special Offer' : 'Confirm Delete'}
                    content={getModalContent()}
                    onClose={handleModalClose}
                    onConfirm={modalType === 'add' ? handleAddSave : modalType === 'edit' ? handleEditSave : handleDeleteConfirm}
                    confirmText={modalType === 'add' ? 'Add' : modalType === 'edit' ? 'Save' : 'Delete'}
                    cancelText="Cancel"
                    confirmButtonClass={modalType === 'add' ? 'bg-primaryColor hover:bg-secondaryColor' : modalType === 'edit' ? 'bg-blue-600 hover:bg-blue-700' : 'bg-red-600 hover:bg-red-700'}
                    disableConfirm={
                        modalType === 'add' ? !title || !price || !description || !image
                            : modalType === 'edit' ? !currentOffer?.title || !currentOffer?.price || !currentOffer?.description
                                : deleteConfirm !== currentOffer?.title}
                />
            )}
        </div>
    )
}

export default AdminSpecialOffers;
