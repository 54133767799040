import React, { useEffect } from 'react';
import { BookAQuote, CommonHeader, MainTitle } from '../../../components';

const CommercialCleaning: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='border-b-2'>
            <CommonHeader
                title='Commercial Cleaning'
                bgImage='assets/images/our-services/commercial-cleaning.jpg'
                mainPath={[
                    { name: 'Home', path: '/' },
                    { name: 'Cleaning Services', path: '/cleaning-services' }
                ]}
            />
            <div className="container mx-auto py-14 space-y-6">
                <MainTitle title='Commercial Cleaning Services by GOBUCKS' />
                <div className="text-gray-700">
                    Welcome to GOBUCKS, your reliable partner in maintaining a pristine, professional environment for your business. Our commercial cleaning services are designed to meet the unique needs of various industries, ensuring that your workspace remains clean, healthy, and inviting. At GOBUCKS, we are dedicated to delivering top-notch cleaning solutions that support your business’s success.
                    <img src="assets/images/our-services/commercial-cleaning.jpg" alt="Commercial Cleaning" className="w-full h-auto mt-5" />
                </div>

                <MainTitle title='Why Choose GOBUCKS for Your Commercial Cleaning Needs?' />
                <div className="text-gray-700">
                    <ul className="list-disc ml-5 space-y-3">
                        <li>Customized Cleaning Plans: We understand that every business has distinct requirements. Our team works closely with you to create a tailored cleaning plan that fits your schedule and specific needs, whether you need daily, weekly, or monthly services.</li>
                        <li>Trained and Professional Staff: Our cleaning professionals are thoroughly trained, experienced, and background-checked. They are committed to providing the highest level of service with a professional and courteous approach, ensuring your workspace is treated with care.</li>
                        <li>High-Quality Cleaning Products: We use effective, industry-grade cleaning products that ensure a thorough clean, creating a safe and healthy environment for your employees and clients.</li>
                        <li>Comprehensive Cleaning Services: From office buildings to retail spaces, we offer a wide range of services that cover every aspect of commercial cleaning. Our attention to detail guarantees a spotless and well-maintained workspace.</li>
                        <li>Satisfaction Guaranteed: Your satisfaction is our priority. We strive to exceed your expectations with every clean, and if you’re not completely satisfied, we’ll make it right.</li>
                    </ul>
                </div>

                <MainTitle title='Our Commercial Cleaning Services Include:' />
                <div className="text-gray-700">
                    <ul className="list-disc ml-5 space-y-3">
                        <li>Office Cleaning: Regular cleaning of offices, including dusting, vacuuming, trash removal, and sanitizing workspaces to create a productive and pleasant environment.</li>
                        <li>Restroom Sanitization: Thorough cleaning and disinfecting of restrooms to ensure they are hygienic and presentable for employees and visitors.</li>
                        <li>Floor Care: Comprehensive floor cleaning services, including sweeping, mopping, vacuuming, and carpet cleaning to maintain clean and safe flooring.</li>
                        <li>Window Cleaning: Professional window cleaning services to keep your windows spotless and streak-free, enhancing the appearance of your business.</li>
                        <li>Common Area Cleaning: Cleaning and maintenance of common areas such as lobbies, break rooms, and conference rooms to ensure they are clean and welcoming.</li>
                        <li>Specialized Cleaning Services: Customized cleaning solutions for various industries, including healthcare facilities, educational institutions, retail stores, and more.</li>
                    </ul>
                </div>

                <div className="text-gray-700">
                    Our regular commercial cleaning packages offer daily, weekly, or bi-weekly services designed to fit seamlessly into your business operations, ensuring you always have a spotless and professional workspace.
                </div>

                <MainTitle title='How It Works:' />
                <div className="text-gray-700">
                    <ol className="list-decimal list-inside mb-6 space-y-3">
                        <li>Book Your Service: Contact us to schedule your commercial cleaning service at a time that is convenient for your business operations.</li>
                        <li>Consultation and Planning: Our team will discuss your specific cleaning needs and develop a customized cleaning plan tailored to your business.</li>
                        <li>Professional Cleaning: Our skilled cleaners will arrive on time and perform the tasks efficiently and thoroughly, according to your customized plan.</li>
                        <li>Quality Check: We ensure everything is cleaned to your satisfaction, with regular quality checks to maintain our high standards.</li>
                    </ol>
                </div>

                <div className="text-gray-700">
                    Experience the GOBUCKS difference and create a cleaner, healthier workspace for your business. Contact us today to schedule your commercial cleaning service and discover why businesses trust GOBUCKS for their cleaning needs.
                </div>
                <div className="flex justify-center flex-col items-center space-y-4">
                    <span className="text-gray-700 uppercase font-bold">Send us a message to book your commercial cleaning service today!</span>
                    <BookAQuote bookQuoteType='send-message' serviceType='commercial-cleaning' />
                </div>
            </div>
        </div>
    );
};

export default CommercialCleaning;
