// ContactUs.tsx
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { container, item } from '../../Constant';
import { BookAQuote, CommonHeader } from '../../components';


const ContactUs: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <CommonHeader
                title='Contact Us'
                bgImage='assets/images/our-services/oven-cleaning.jpg'
                mainPath={[{ name: 'Home', path: '/' }]}
            />
            <motion.div variants={container} initial="hidden" animate="show" className='mx-auto px-5 sm:px-10 py-5 md:py-0 min-h-[calc(100vh-64px)] container'>
                <motion.div
                    variants={item} className='pt-3 sm:pt-10'>
                    <h1 className='text-4xl text-left font-bold'>Contact Us</h1>
                    <span className='text-left block mt-2'>
                        {`We're here to help. Reach out to us for any inquiries.`}
                    </span>
                </motion.div>
                <motion.div
                    variants={item} className='grid grid-cols-1 md:grid-cols-2 gap-10 mt-10'>
                    <div className='flex flex-col md:w-1/3 gap-10'>
                        <div className='flex flex-col'>
                            <span> <i className='fas fa-envelope'></i><b className='ml-2'>Email</b> </span>
                            <span> Send us an email today</span>
                            <a href="mailto:info@gobucks.co.uk" className='text-red-800'>
                                info@gobucks.co.uk
                            </a>
                        </div>
                        <div className='flex flex-col'>

                            <span> <i className='fas fa-phone-alt'></i> <b className='ml-2'>Phone</b> </span>
                            <span> Give us a call now</span>
                            <a href="tel:07897969232" className='text-red-800'>
                                0789 796 92 32
                            </a>
                        </div>
                        <div className='flex flex-col'>

                            <span> <i className='fas fa-map-marker-alt'></i> <b className='ml-2'>Address</b> </span>
                            <span>
                                19 Willow Court <br />
                                Intalbury Avenue <br />
                                Aylesbury, Buckinghamshire,<br />
                                HP19 8HE <br />
                                United Kingdom.
                            </span>
                            <a
                                href="https://www.google.com/maps/search/Willow+Court+19+Intalbury+Ave+Aylesbury+HP19+8HE+UK/@51.824515,-0.838032,16z?hl=en&entry=ttu"
                                target='_blank'
                                className='text-red-800'
                                rel="noreferrer"
                            >
                                Get Directions
                            </a>
                        </div>
                    </div>
                    <div className='w-full'>
                        <div className='space-y-5 bg-primaryColor p-5 rounded-lg'>
                            <div className='text-center font-bold text-lg md:text-2xl text-white'>GET A FREE CALL BACK</div>
                            <BookAQuote bookQuoteType='contact-us' />
                        </div>
                    </div>
                </motion.div>
            </motion.div>
        </div>
    );
};

export default ContactUs;
