import React from 'react'
import { Link } from 'react-router-dom'

const ComingSoon: React.FC = () => {
    return (
        <div className="min-h-screen bg-primaryColor flex flex-col items-center justify-center px-10 text-center">
            <h1 className="text-5xl text-secondaryColor font-bold mb-8 animate-pulse">
                Coming Soon
            </h1>
            <p className="text-white text-lg mb-8">
                We're working hard to bring you something amazing. Stay tuned!
            </p>
            <Link to="/" className="text-white hover:text-secondaryColor">
                <i className="fas fa-arrow-left mr-2"></i> Go back to home
            </Link>
        </div>
    )
}

export default ComingSoon
