import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { BookAQuote, SpecialOfferCard } from '../../../components';
import { motion, AnimatePresence } from 'framer-motion';
import { useAppContext } from '../../../../contexts/AppContext';

const HeroSection: React.FC = () => {
    const { specialOffers } = useAppContext();

    const getRandomIndices = useCallback(() => {
        const start = Math.floor(Math.random() * (specialOffers.length - 1));
        const end = start + 1;
        return { start, end };
    }, [specialOffers.length]);

    const [index, setIndex] = useState(0);
    const [direction, setDirection] = useState(1);
    const [randomIndices, setRandomIndices] = useState<{ start: number, end: number } | null>(null);
    const autoSlideRef = useRef<number | undefined>(undefined);

    const renderSlides = useMemo(() => [
        {
            type: 'image',
            src: 'assets/images/background/bg-image-8.png',
            alt: 'image',
            firstTitle: 'You Can Find More Special Offers Here',
            secondTitle: '',
            thirdTitle: '',
            shopMore: {
                title: 'Special Offers',
                link: '/special-offers',
            },
            LearnMore: {
                title: '',
                link: '',
            },
            content: specialOffers && randomIndices && (
                <div>
                    <SpecialOfferCard data={specialOffers} isSlice start={randomIndices.start} end={randomIndices.end} />
                </div>
            ),
            allContent: true,
        },
        {
            type: 'image',
            src: 'assets/images/background/bg-image-7.png',
            alt: 'image',
            firstTitle: 'The Cleaning Company in Aylesbury You Can Trust',
            secondTitle: '',
            thirdTitle: '',
            shopMore: {
                title: '',
                link: '',
            },
            LearnMore: {
                title: '',
                link: '',
            },
            content: <BookAQuote id='hero' bookQuoteType='book-quote' />,
            allContent: true,
        },
        {
            type: 'image',
            src: 'assets/images/background/bg-image-9.png',
            alt: 'image',
            firstTitle: 'Our Cleaning Services',
            secondTitle: '',
            thirdTitle: '',
            shopMore: {
                title: 'More Services',
                link: '/cleaning-services',
            },
            LearnMore: {
                title: '',
                link: '',
            },
            content: '',
            allContent: true,
        },
    ], [randomIndices, specialOffers]);

    useEffect(() => {
        if (specialOffers && specialOffers.length > 0) {
            setRandomIndices(getRandomIndices());
        }
    }, [specialOffers, getRandomIndices]);

    useEffect(() => {
        autoSlideRef.current = window.setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % renderSlides.length);
            setDirection(1);
            setRandomIndices(getRandomIndices());
        }, 20000);

        return () => {
            if (autoSlideRef.current !== undefined) {
                clearInterval(autoSlideRef.current);
            }
        };
    }, [getRandomIndices, renderSlides.length]);

    const handleFocus = () => {
        if (autoSlideRef.current !== undefined) {
            clearInterval(autoSlideRef.current);
        }
    };

    const handleBlur = () => {
        autoSlideRef.current = window.setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % renderSlides.length);
            setDirection(1);
            setRandomIndices(getRandomIndices());
        }, 13000);
    };

    const slideVariants = {
        enter: (direction: number) => ({
            opacity: 0,
            x: direction === 1 ? 100 : -100,
        }),
        center: { opacity: 1, x: 0 },
        exit: (direction: number) => ({
            opacity: 0,
            x: direction === 1 ? -100 : 100,
        }),
    };

    const contentVariants = {
        enter: { opacity: 0, y: 50 },
        center: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -50 },
    };

    const nextSlide = () => {
        setIndex((prevIndex) => (prevIndex + 1) % renderSlides.length);
        setRandomIndices(getRandomIndices());
        setDirection(1);
    };

    const prevSlide = () => {
        setIndex((prevIndex) => (prevIndex - 1 + renderSlides.length) % renderSlides.length);
        setRandomIndices(getRandomIndices());
        setDirection(-1);
    };

    return (
        <div className="relative h-[calc(100vh-4rem)] overflow-hidden">
            <AnimatePresence initial={false} custom={direction}>
                {renderSlides.map((slide, slideIndex) =>
                    index === slideIndex ? (
                        <motion.div
                            key={slideIndex}
                            className="absolute top-0 left-0 w-full h-full"
                            initial="enter"
                            animate="center"
                            exit="exit"
                            variants={slideVariants}
                            custom={direction}
                            transition={{ duration: 0.7 }}
                        >
                            {slide.type === 'image' ? (
                                <img
                                    src={slide.src}
                                    alt={slide.alt}
                                    className="w-full h-full object-cover object-top"
                                />
                            ) : (
                                <video
                                    className="w-full h-full object-cover object-top"
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                >
                                    <source src={slide.src} type="video/mp4" />
                                </video>
                            )}
                            <div className="absolute inset-0 bg-[#000000b7] bg-opacity-50"></div>
                            <motion.div
                                className="absolute inset-0 flex flex-row justify-center items-center lg:p-60"
                                initial="enter"
                                animate="center"
                                exit="exit"
                                variants={contentVariants}
                                transition={{ duration: 1 }}
                            >
                                <div className='container mx-auto flex flex-col md:flex-row justify-center items-center gap-8 md:justify-around text-white text-center'>
                                    {slide.allContent &&
                                        <div className='border-l-4 border-primaryColor pl-3 w-full'>
                                            {slide.firstTitle &&
                                                <h1 className="text-3xl">{slide.firstTitle}</h1>
                                            }
                                            {slide.secondTitle &&
                                                <h2 className="text-xl md:text-3xl font-bold w-[100%] text-center">
                                                    {slide.secondTitle}
                                                </h2>
                                            }

                                            {slide.thirdTitle && <p className="text-sm font-light">{slide.thirdTitle}</p>}

                                            {slide.shopMore.title &&
                                                <div className="flex justify-center items-center gap-5 mt-5 uppercase whitespace-nowrap">
                                                    <Link
                                                        to={slide.shopMore.link}
                                                        className="text-xs bg-white text-primaryColor px-5 py-[0.7rem] cursor-pointer hover:bg-primaryColor hover:text-white duration-300 transition-all ease-in-out"
                                                    >
                                                        {slide.shopMore.title}
                                                    </Link>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {slide.content &&
                                        <div
                                            className={`max-w-[300px] ${index === 0 ? 'hidden md:block' : ''}`}
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                        >
                                            {slide.content}
                                        </div>
                                    }
                                </div>
                            </motion.div>
                        </motion.div>
                    ) : null
                )}
            </AnimatePresence>
            {/* Navigation Arrows */}
            <button
                className="hidden md:block absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75"
                onClick={prevSlide}
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </button>
            <button
                className="hidden md:block absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75"
                onClick={nextSlide}
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
            </button>
            {/* Bullet points */}
            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 flex items-center gap-2 shadow-md bg-[#fafafa0a] p-2 rounded-full">
                {renderSlides.map((_, i) => (
                    <div
                        key={i}
                        className={`w-2 h-2 rounded-full ${i === index ? 'bg-secondaryColor' : 'bg-white'} cursor-pointer`}
                        onClick={() => {
                            setIndex(i);
                            setDirection(i > index ? 1 : -1);
                        }}
                    ></div>
                ))}
            </div>
        </div>
    );
};

export default HeroSection;
