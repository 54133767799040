/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

interface InputFieldProps {
    label?: string;
    type: "text" | "password" | "textarea" | "date" | "time" | "radio" | "select" | "number" | "email" | "checkbox";
    id: string;
    name: string;
    placeholder: string;
    value?: any;
    className?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, id?: string) => void;
    outline?: boolean;
    disabled?: boolean;
    options?: { label: string; value: string }[];
    rows?: number;
    cols?: number;
    min?: number;
    max?: number;
    error?: string;
    checked?: boolean;
    required?: boolean;

}

const InputField: React.FC<InputFieldProps> = ({ label, type, disabled, name, placeholder, id, value, className, onChange, outline, options, rows, cols, min, max, error = "", checked, required = false }) => {
    const [showPassword, setShowPassword] = React.useState(false);

    switch (type) {
        case "text":
            return (
                <div className="flex flex-col w-full gap-2">
                    {label && (
                        <label htmlFor={id} className="text-black font-normal">
                            {label}
                        </label>
                    )}
                    <input
                        type="text"
                        id={id}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        className={`w-full rounded-md py-2 px-2 border ${className} ${error !== "" ? 'border-red-500' : 'border-gray-300'}`}
                        onChange={(e) => onChange && onChange(e)}
                        disabled={disabled}
                        required={required}
                    />
                    {error !== "" && <p className="text-red-500 text-xs">{error}</p>}
                </div>
            );
        case "email":
            return (
                <div className="flex flex-col w-full gap-2">
                    {label && (
                        <label htmlFor={id} className="text-black font-normal">
                            {label}
                        </label>
                    )}
                    <input
                        type="email"
                        id={id}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        className={`w-full rounded-md py-2 px-2 border ${className} ${error !== "" ? 'border-red-500' : 'border-gray-300'}`}
                        onChange={(e) => onChange && onChange(e)}
                        disabled={disabled}
                        required={required}
                    />
                    {error !== "" && <p className="text-red-500 text-xs">{error}</p>}
                </div>
            );
        case "number":
            return (
                <div className="flex flex-col w-full gap-2">
                    {label && <label htmlFor={id} className='text-black font-normal'>{label}</label>}
                    <input
                        type="number"
                        id={id}
                        name={name}
                        placeholder={placeholder}
                        value={Number(value)}
                        className={`w-full rounded-md py-2 px-2 border  ${className} ${error !== "" ? 'border-red-500' : 'border-gray-300'}`}
                        onChange={(e) => onChange && onChange(e)}
                        disabled={disabled}
                        min={min}
                        max={max}
                        required={required}
                    />
                    {error !== "" && <p className="text-red-500 text-xs">{error}</p>}
                </div>
            );
        case "password":
            return (
                <div className="flex flex-col w-full gap-2">
                    {label && <label htmlFor={id} className='text-black font-normal'>{label}</label>}
                    <div className="relative w-full">
                        <input
                            type={showPassword ? "text" : "password"}
                            id={id}
                            name={name}
                            placeholder={placeholder}
                            value={value}
                            className={`w-full rounded-md py-2 px-2 border  ${className} ${error !== "" ? 'border-red-500' : 'border-gray-300'}`}
                            onChange={(e) => onChange && onChange(e)}
                            disabled={disabled}
                            required={required}
                        />
                        <div
                            onClick={() => setShowPassword(!showPassword)}
                            className="text-black text-sm absolute right-2 bottom-0 transform -translate-y-1/2 cursor-pointer"
                        >
                            <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                        </div>
                    </div>
                    {error !== "" && <p className="text-red-500 text-xs">{error}</p>}
                </div>
            );

        case "textarea":
            return (
                <div className="flex flex-col w-full gap-2">
                    {label && <label htmlFor={id} className='text-black font-normal'>{label}</label>}
                    <textarea
                        id={id}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        className={`w-full rounded-md py-2 px-2 border  ${className} ${error !== "" ? 'border-red-500' : 'border-gray-300'}`}
                        onChange={(e) => onChange && onChange(e)}
                        disabled={disabled}
                        rows={rows ? rows : 3}
                        cols={cols ? cols : 20}
                        required={required}
                    />
                    {error !== "" && <p className="text-red-500 text-xs">{error}</p>}
                </div>
            );
        case "date":
            return (
                <div className="flex flex-col w-full gap-2">
                    {label && <label htmlFor={id} className='text-black font-normal'>{label}</label>}
                    <div className={`${outline ? 'border border-gray-300 rounded-md' : ''}`}>
                        <input
                            type="date"
                            id={id}
                            name={name}
                            placeholder={placeholder}
                            defaultValue={value}
                            className={`w-full rounded-md py-2 px-2 border  ${className} ${error !== "" ? 'border-red-500' : 'border-gray-300'}`}
                            onChange={(e) => onChange && onChange(e)}
                            disabled={disabled}
                            max={max}
                            min={min}
                            required={required}
                        />
                    </div>
                    {error !== "" && <p className="text-red-500 text-xs">{error}</p>}
                </div>
            );
        case "time":
            return (
                <div className="flex flex-col w-full gap-2">
                    {label && <label htmlFor={id} className='text-black font-normal'>{label}</label>}
                    <div className={`${outline ? 'border border-gray-300 rounded-md' : ''}`}>
                        <input
                            type="time"
                            id={id}
                            name={id}
                            placeholder={placeholder}
                            value={value}
                            className={`w-full rounded-md py-2 px-2 border  ${className} ${error !== "" ? 'border-red-500' : 'border-gray-300'}`}
                            onChange={(e) => onChange && onChange(e)}
                            disabled={disabled}
                            required={required}
                        />
                    </div>
                    {error !== "" && <p className="text-red-500 text-xs">{error}</p>}
                </div>
            );
        case "radio":
            return (
                <div className="flex flex-col w-full gap-2">
                    {label && (
                        <label htmlFor={id} className="text-black font-normal">
                            {label}
                        </label>
                    )}
                    <div className="flex gap-2">
                        {options?.map((option) => (
                            <label key={option.value} className="flex items-center">
                                <input
                                    type="radio"
                                    id={option.value}
                                    name={name}
                                    value={option.value}
                                    onChange={(e) => onChange && onChange(e)}
                                    disabled={disabled}
                                    required={required}
                                />
                                {option.label}
                            </label>
                        ))}
                    </div>
                    {error !== "" && <p className="text-red-500 text-xs">{error}</p>}
                </div>
            );

        case "checkbox":
            return (
                <div className="flex flex-col w-full gap-2">
                    {label && (
                        <label htmlFor={id} className="text-black font-normal">
                            {label}
                        </label>
                    )}
                    <div className="flex gap-2 items-center">
                        <input
                            type="checkbox"
                            className=""
                            id={id}
                            name={name}
                            checked={checked}
                            onChange={(e) => onChange && onChange(e)}
                            disabled={disabled}
                            required={required}
                        />
                        <label htmlFor={id} className={`${className}`} >{placeholder}</label>
                    </div>
                    {error !== "" && <p className="text-red-500 text-xs">{error}</p>}
                </div>
            );
        case "select":
            return (
                <div className="flex flex-col w-full gap-2">
                    {label && (
                        <label htmlFor={id} className="text-black font-normal">
                            {label}
                        </label>
                    )}
                    <select
                        id={id}
                        name={name}
                        value={value}
                        className={`w-full rounded-md h-[42px] py-2 px-2 border  ${className} ${error !== "" ? 'border-red-500' : 'border-gray-300'}`}
                        onChange={(e) => onChange && onChange(e)}
                        disabled={disabled}
                        required={required}
                    >
                        {options?.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                    {error !== "" && <p className="text-red-500 text-xs">{error}</p>}
                </div>
            );
        default:
            return (
                <div className="flex flex-col space-y-3">
                    <label htmlFor={id}>{label}</label>
                    <input
                        type={type}
                        id={id}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        className={`w-full rounded-md py-2 px-2 border border-gray-300 ${className}`}
                        onChange={(e) => onChange && onChange(e)}
                        disabled={disabled}
                        required={required}
                    />
                    {error !== "" && <p className="text-red-500 text-xs">{error}</p>}
                </div>
            );
    }
};

export default InputField;
