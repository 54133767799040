import React from 'react'
import { CleaningCard } from '../../../components'

export const services = [
    {
        title: 'Domestic Cleaning Aylesbury',
        description: 'DOMESTIC CLEANING',
        image: 'assets/images/our-services/domestic-cleaning.jpg',
        link: '/cleaning-services/domestic-cleaning'
    },
    {
        title: 'Eco Friendly Cleaning Services Aylesbury',
        description: 'ECO - FRIENDLY CLEANING',
        image: 'assets/images/our-services/eco-friendly-cleaning.jpg',
        link: '/cleaning-services/eco-friendly-cleaning'

    },
    {
        title: 'Commercial Cleaning Aylesbury',
        description: 'COMMERCIAL CLEANING',
        image: 'assets/images/our-services/commercial-cleaning.jpg',
        link: '/cleaning-services/commercial-cleaning'
    },
    {
        title: 'One Off Deep Cleaning Aylesbury',
        description: 'ONE - OFF DEEP CLEANING',
        image: 'assets/images/our-services/one-off-deep-cleaning.jpg',
        link: '/cleaning-services/one-off-deep-cleaning'
    },
    {
        title: 'End Of Tenancy Cleaning Services',
        description: 'MOVE IN MOVE OUT',
        image: 'assets/images/our-services/end-of-tenancy-cleaning.jpg',
        link: '/cleaning-services/end-of-tenancy-cleaning'
    },
    {
        title: 'Carpet Cleaning Services Aylesbury',
        description: 'CARPET CLEANING',
        image: 'assets/images/our-services/carpet-cleaning.jpg',
        link: '/cleaning-services/carpet-cleaning'
    },
    {
        title: 'Oven Cleaning Aylesbury',
        description: 'OVEN CLEANING',
        image: 'assets/images/our-services/oven-cleaning.jpg',
        link: '/cleaning-services/oven-cleaning'
    }
]

const OurServices: React.FC = () => {

    return (
        <div className='bg-bgGray'>
            <div className='container mx-auto py-14'>
                <div className='uppercase text-primaryColor font-bold text-center text-2xl mb-5'>
                    Our Services
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
                    {services.map((service, index) => (
                        <CleaningCard key={index} data={service} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default OurServices
