import React, { useEffect } from 'react'
import { BookAQuote, CommonHeader, MainTitle } from '../../../components'

const MoveCleaning: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='border-b-2'>
            <CommonHeader
                title='Move-In and Move-Out Cleaning Services'
                bgImage='assets/images/our-services/end-of-tenancy-cleaning.jpg'
                mainPath={[
                    { name: 'Home', path: '/' },
                    { name: 'Cleaning Services', path: '/cleaning-services' }
                ]}
            />
            <div className="container mx-auto py-14 space-y-6">
                <MainTitle title='Move-In and Move-Out Cleaning Services by GOBUCKS' />
                <div className="text-gray-700">
                    Moving can be a stressful experience, but GOBUCKS is here to make it easier with our comprehensive move-in and move-out cleaning services. We understand the importance of leaving a spotless space behind and starting fresh in a clean new home. Our expert team ensures that both your old and new residences are immaculate, allowing you to focus on the excitement of your move.
                    <img src="assets/images/our-services/end-of-tenancy-cleaning.jpg" alt="Eco-Friendly Cleaning" className="w-full h-auto mt-5" />
                </div>

                <MainTitle title='Why Choose GOBUCKS for Move-In and Move-Out Cleaning?' />
                <div className="text-gray-700">
                    <ul className="list-disc ml-5 space-y-3">
                        <li>Thorough and Meticulous Cleaning: Our move-in and move-out cleaning services cover every corner of your home. We pay attention to every detail to ensure the space is thoroughly cleaned and ready for its next occupants.</li>
                        <li>Experienced and Reliable Team: Our professional cleaners are highly trained, experienced, and reliable. They bring a high level of expertise and care to every cleaning job, ensuring exceptional results.</li>
                        <li>High-Quality Products and Equipment: We use top-quality, eco-friendly cleaning products and advanced equipment to deliver a deep and effective clean without harming the environment.</li>
                        <li>Customized Cleaning Plans: We tailor our services to meet your specific needs, whether you need a comprehensive clean of an entire house or focus on particular areas. We work according to your requirements and timeline.</li>
                        <li>Satisfaction Guaranteed: Your satisfaction is our top priority. If you’re not completely satisfied with our service, we’ll make it right to ensure you are happy with the results.</li>
                    </ul>
                </div>

                <MainTitle title='Our Move-In and Move-Out Cleaning Services Include:' />
                <div className="text-gray-700">
                    <ul className="list-disc ml-5 space-y-3">
                        <li>Living Areas: Deep cleaning of living rooms, dining rooms, and other common areas, including dusting, vacuuming, mopping floors, and cleaning windows and surfaces.</li>
                        <li>Kitchen Cleaning: Detailed cleaning of all kitchen appliances, countertops, cabinets, and floors. We ensure sinks and faucets are sanitized, and every surface is left sparkling clean.</li>
                        <li>Bathroom Cleaning: Comprehensive cleaning and sanitization of bathrooms, including toilets, showers, bathtubs, sinks, mirrors, and tiles. We remove any build-up and ensure the space is hygienic.</li>
                        <li>Bedroom Cleaning: Thorough cleaning of bedrooms, including dusting, vacuuming, and mopping floors, cleaning under furniture, and organizing closets and storage areas.</li>
                        <li>Additional Services: We also offer specialized services such as carpet cleaning, upholstery cleaning, and window washing to address all aspects of your move-in or move-out cleaning needs.</li>
                    </ul>
                </div>

                <MainTitle title='How It Works:' />
                <div className="text-gray-700">
                    <ol className="list-decimal list-inside mb-6 space-y-3">
                        <li>Book Your Service: Contact us to schedule your move-in or move-out cleaning service at a time that fits your moving schedule.</li>
                        <li>Consultation and Assessment: Our team will discuss your specific cleaning needs and conduct an assessment to create a customized cleaning plan.</li>
                        <li>Professional Cleaning: Our experienced cleaners will arrive on time and perform a thorough, detailed cleaning of the specified areas, ensuring your old or new home is spotless.</li>
                        <li>Final Inspection: We conduct a final inspection to ensure the cleaning meets our high standards and your satisfaction.</li>
                    </ol>
                </div>

                <div className="text-gray-700">
                    Make your move stress-free with GOBUCKS’ move-in and move-out cleaning services. Contact us today to schedule your cleaning service and experience the convenience and peace of mind that comes with a professionally cleaned home.
                </div>
                <div className="flex justify-center flex-col items-center space-y-4">
                    <span className="text-gray-700 uppercase font-bold">Send us a message to book your move-in or move-out cleaning service today!</span>
                    <BookAQuote bookQuoteType='send-message' serviceType='end-of-tenancy-cleaning' />
                </div>
            </div>
        </div>
    )
}

export default MoveCleaning
