import React from 'react'
interface MainTitleProps {
    title: string;
}

const MainTitle: React.FC<MainTitleProps> = ({ title }) => {
    return (
        <div className="uppercase text-primaryColor font-bold text-left text-2xl mb-5 leading-[3rem]">
            <div>
                {title}
            </div>
            <div className="relative">
                <div className="absolute inset-0 flex items-center">
                    <div className="h-[0.25rem] z-10 w-10 bg-secondaryColor rounded-md"></div>
                </div>
                <div className="absolute inset-0 flex items-center">
                    <div className="h-0.5 w-full bg-primaryColor rounded-md"></div>
                </div>
            </div>
        </div>
    )
}

export default MainTitle
