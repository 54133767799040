import React from 'react'
import { useNavigate } from 'react-router-dom'

interface CleaningCardProps {
    data: {
        image: string;
        description: string;
        link: string;
        title: string;
    }
}

const CleaningCard: React.FC<CleaningCardProps> = ({ data }) => {
    const navigate = useNavigate()
    return (
        <div>
            <div
                className='group shadow-md relative rounded-lg cursor-pointer overflow-hidden transition duration-300 ease-in-out transform hover:scale-[1.02] hover:shadow-lg hover:bg-[#f1f1f1]'
                onClick={() => navigate(data.link)}
            >
                <img
                    src={data.image ? data.image : '/images/background/bg-image-7.png'}
                    alt={data.title}
                    className='w-full h-60 object-cover rounded-t-lg'
                />
                <div className='absolute inset-0 bg-black bg-opacity-50 group-hover:bg-opacity-80 flex flex-col items-center justify-center '>
                    <p className='text-white text-center text-lg font-semibold group-hover:text-secondaryColor'>{data.description}</p>
                </div>
            </div>
        </div >
    )
}

export default CleaningCard
