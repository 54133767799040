import React, { useRef } from "react";
import navigationConfig from "../../routes/navigationConfig";
import NavBar from "../navBar/navBar";
import Footer from "../footer/footer";
import OurLocation from "../our-location";
import GetInTouch from "../get-in-touch";
import FloatingButtons from "../buttons/floating-button";

interface UserLayoutProps {
    children: React.ReactNode;
}

const UserLayout: React.FC<UserLayoutProps> = ({ children }) => {
    const bookAQuoteRef = useRef<HTMLDivElement>(null);

    const scrollToQuote = () => {
        if (bookAQuoteRef.current) {
            bookAQuoteRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <div className="flex min-h-screen justify-between flex-col bg-shade">
            <NavBar navigation={navigationConfig} />
            <div className="pt-[calc(50px+1rem)] min-h-screen ">
                {children}
            </div>
            <OurLocation />
            <GetInTouch />
            <Footer navigation={navigationConfig} bookAQuoteRef={bookAQuoteRef} />
            <div className="fixed bottom-5 right-5 z-50 flex flex-col space-y-2">
                <FloatingButtons onClick={scrollToTop} icon="fas fa-chevron-up" divOrAnchor="div" />
                <FloatingButtons onClick={() => { }} icon="fas fa-phone" href="tel:1234567890" divOrAnchor="a" />
                <FloatingButtons onClick={scrollToQuote} icon="fas fa-comment-dots" divOrAnchor="div" />
            </div >
        </div >
    );
}

export default UserLayout;
