import React, { useEffect } from 'react'
import { BookAQuote, CommonHeader, MainTitle } from '../../../components'

const DomesticCleaning: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <div className='border-b-2'>
            <CommonHeader
                title='Domestic Cleaning'
                bgImage='assets/images/our-services/domestic-cleaning.jpg'
                mainPath={[
                    { name: 'Home', path: '/' },
                    { name: 'Cleaning Services', path: '/cleaning-services' }]
                }
            />
            <div className="container mx-auto py-14 space-y-6">
                <MainTitle title='Domestic Cleaning Services by GOBUCKS' />
                <div className="text-gray-700">
                    Welcome to GOBUCKS, your trusted partner in creating a spotless, comfortable home environment. Our domestic cleaning services are designed to cater to your specific needs, ensuring your home is always a clean, welcoming haven for you and your family.At GOBUCKS, we pride ourselves on our attention to detail, commitment to quality, and customer satisfaction.
                    <img src="assets/images/our-services/domestic-cleaning.jpg" alt="Domestic Cleaning" className="w-full h-auto mt-5" />
                </div>
                <MainTitle title='Why Choose GOBUCKS for Your Domestic Cleaning Needs ?' />
                <div className="text-gray-700">
                    <ul className="list-disc ml-5 space-y-3">
                        <li>Customized Cleaning Plans: We understand that every home is unique.Our team works with you to develop a personalized cleaning plan that fits your lifestyle and preferences.Whether you need a one - time deep clean or regular maintenance, we’ve got you covered.</li>
                        <li>Experienced and Professional Staff: Our cleaning professionals are thoroughly vetted, trained, and experienced. They bring a high level of professionalism and expertise to ensure your home is cleaned to the highest standards.</li>
                        <li>Eco - Friendly Products: GOBUCKS is committed to protecting your home and the environment. We use eco - friendly cleaning products that are safe for your family and pets while effectively eliminating dirt and germs.</li>
                        <li>Comprehensive Cleaning Services: From dusting and vacuuming to kitchen and bathroom sanitization, our comprehensive services cover every corner of your home.We also offer specialized services like carpet cleaning, window washing, and more.</li>
                        <li>Satisfaction Guaranteed: Your satisfaction is our top priority. If you’re not completely happy with our service, we’ll make it right.Our commitment to quality means we always strive to exceed your expectations.</li>
                    </ul>
                </div>
                <MainTitle title='Our Domestic Cleaning Services Include:' />
                <div className="text-gray-700">
                    <ul className="list-disc ml-5 space-y-3">
                        <li>General Cleaning: Regular cleaning tasks such as dusting, vacuuming, mopping, and wiping surfaces to keep your home fresh and tidy.</li>
                        <li>Deep Cleaning: A thorough clean that targets hard - to - reach areas and stubborn dirt, perfect for spring cleaning or preparing your home for special occasions.</li>
                        <li>Kitchen Cleaning: Detailed cleaning of all kitchen surfaces, including countertops, appliances, and cabinets, to ensure a hygienic cooking environment.</li>
                        <li>Bathroom Cleaning: Comprehensive cleaning and sanitization of bathrooms, including toilets, showers, sinks, and mirrors.</li>
                        <li>Bedroom Cleaning: Dusting, vacuuming, and changing bed linens to create a comfortable and clean sleeping environment.</li>
                        <li>Living Areas: Cleaning and organizing living rooms, dining rooms, and other common areas to enhance your home’s overall appearance.</li>
                    </ul>
                </div>
                <div className="text-gray-700">
                    Our regular domestic cleaning packages offer daily, weekly, or bi - weekly services designed to fit seamlessly into your lifestyle, ensuring you always return to a spotless and serene home.
                </div>

                <MainTitle title='How It Works:' />
                <div className="text-gray-700">
                    <ol className="list-decimal list-inside mb-6 space-y-3">
                        <li>Schedule an Appointment: Contact us to schedule a convenient time for your cleaning service.</li>
                        <li>Personalized Consultation: Our team will discuss your cleaning needs and create a customized plan.</li>
                        <li>Professional Cleaning: Our skilled cleaners will arrive on time and perform the agreed - upon tasks efficiently and thoroughly.</li>
                        <li>Final Inspection: We ensure everything is cleaned to your satisfaction before leaving your home.</li>
                    </ol>
                </div>
                <div className="text-gray-700">
                    Experience the GOBUCKS difference and enjoy a cleaner, healthier home.Contact us today to schedule your domestic cleaning service and discover why so many homeowners trust GOBUCKS for their cleaning needs.
                </div>
                <div className="flex justify-center flex-col items-center space-y-4">
                    <span className="text-gray-700 uppercase font-bold">Send us a message to book your domestic cleaning service today!</span>
                    <BookAQuote bookQuoteType='send-message' serviceType='domestic-cleaning' />
                </div>
            </div>
        </div>
    )
}

export default DomesticCleaning
