import React from 'react';
import HeroSection from './widgets/heroSection';
import OurServices from './widgets/our-services';

const Home: React.FC = () => {

    return (
        <>
            <HeroSection />
            <OurServices />

        </>
    );
};

export default Home;
