import React from 'react'
import { motion } from 'framer-motion';
import { container, item } from '../../../Constant';
import { Link } from 'react-router-dom';
interface CommonHeaderProps {
    title: string;
    bgImage: string;
    mainPath?: { name: string, path: string }[];
}

const CommonHeader: React.FC<CommonHeaderProps> = ({ title, bgImage, mainPath }) => {
    return (
        <motion.div
            variants={container}
            initial="hidden"
            animate="show"
            className={`bg-cover bg-center bg-no-repeat flex flex-col items-center justify-center h-[220px] uppercase overflow-hidden relative`}
            style={{
                backgroundImage: `url(${bgImage})`
            }}
        >
            <div className='bg-[#000000b3] w-full h-full absolute top-0 left-0'></div>
            <motion.div
                animate={{
                    opacity: 1,
                    left: 0,
                    right: 0,
                }}
                transition={{
                    duration: 0.3,
                    ease: 'easeInOut',
                    delay: 0.2,
                }}
                variants={item}
                className="z-[1] text-[22px] md:text-[28px] text-white font-semibold text-center tracking-tight">
                {title ? title : 'Header Title'}
            </motion.div>
            <motion.div
                animate={{
                    opacity: 1,
                    left: 0,
                    right: 0,
                }}
                transition={{
                    duration: 0.3,
                    ease: 'easeInOut',
                    delay: 0.5,
                }}
                variants={item}
                className="z-[1] text-[14px] text-white text-right space-y-2 tracking-wide space-x-2 flex items-center flex-wrap justify-center"
            >
                <div>
                    <i className='fa fa-home mt-[0.6rem] text-[18px]'> </i>
                </div>
                {/* {mainPath &&
                    <div className='space-x-2'>
                        <i className='fa fa-arrow-right text-[#b3b0b0bd]'></i>
                        <Link to={mainPath.path ? mainPath.path : '/'} className='font-medium text-[#ffffffe3] hover:text-[#b3b0b0bd] cursor-pointer'>
                            {mainPath.name ? mainPath.name : 'Main Path'}
                        </Link>
                    </div>
                } */}
                {mainPath && mainPath.length > 0 &&
                    mainPath.map((path, index) => (
                        <div key={index} className='space-x-2'>
                            <i className='fa fa-arrow-right text-[#b3b0b0bd]'></i>
                            <Link to={path.path ? path.path : '/'} className='font-medium text-[#ffffffe3] hover:text-[#b3b0b0bd] cursor-pointer'>
                                {path.name ? path.name : 'Main Path'}
                            </Link>
                        </div>
                    ))
                }
                <div className='space-x-2'>
                    <i className='fa fa-arrow-right text-[#b3b0b0bd]'></i>
                    <span className='font-medium text-[#b3b0b0e3]'>{title ? title : 'Header Title'}</span>
                </div>
            </motion.div>
        </motion.div>
    )
}

export default CommonHeader;