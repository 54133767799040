import React, { useEffect } from "react";
import { CommonHeader } from "../../components";
import { useNavigate } from "react-router-dom";

// assets/images/profile/malith-hettiarachchige.jpg
const About: React.FC = () => {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='border-b-2'>
            <CommonHeader
                title="About"
                bgImage="assets/images/our-services/oven-cleaning.jpg"
                mainPath={[{ name: "Home", path: "/" }]}
            />
            <div className="container mx-auto py-14">
                <div className="uppercase text-primaryColor font-bold text-center text-2xl mb-5">
                    About Us - Gobucks
                </div>
                <div className="space-y-5">
                    {/* <div>
                        <img
                            src="assets/images/profile/malith-hettiarachchige.jpg"
                            alt="Malith Hettiarachchige"
                            className="mx-auto rounded-3xl w-48 h-48 object-cover"
                        />
                        <span className="block text-center text-xl text-gray-500 my-2">
                            Founder & CEO
                        </span>
                        <div className="text-center text-xl text-gray-500 my-2">
                            Malith Hettiarachchige
                        </div>
                    </div> */}
                    <div>
                        <div className="text-left text-xl text-gray-500 my-2">
                            1. Who We Are:
                        </div>
                        <div className="text-gray-700 ml-6">
                            Gobucks is a premier cleaning service provider dedicated to
                            offering top-quality commercial and residential cleaning solutions.
                        </div>
                    </div>
                    <div>
                        <div className="text-left text-xl text-gray-500 my-2">
                            2. Our Mission:
                        </div>
                        <div className="text-gray-700 ml-6">
                            To deliver exceptional cleaning services that exceed our clients{`'`}
                            expectations, ensuring a clean and healthy environment for all.
                        </div>
                    </div>
                    <div>
                        <div className="text-left text-xl text-gray-500 my-2">
                            3. Our Services:
                        </div>
                        <div className="text-gray-700 ml-6">
                            We offer a comprehensive range of services, including regular
                            housekeeping, office cleaning, deep cleaning, and emergency cleaning
                            services.
                        </div>
                    </div>
                    <div>
                        <div className="text-left text-xl text-gray-500 my-2">
                            4. Areas We Serve:
                        </div>
                        <div className="text-gray-700 ml-6">
                            We proudly serve clients throughout Aylesbury and the Home Counties,
                            ensuring prompt and reliable cleaning services.
                        </div>
                    </div>
                    <div>
                        <div className="text-left text-xl text-gray-500 my-2">
                            5. Our Commitment:
                        </div>
                        <div className="text-gray-700 ml-6">
                            Our team of professional cleaners is highly trained, fully insured,
                            and equipped with the latest cleaning tools and techniques to
                            provide the best possible service.
                        </div>
                    </div>
                    <div>
                        <div className="text-left text-xl text-gray-500 my-2">
                            6. Why Choose Us:
                        </div>
                        <div className="text-gray-700 ml-6 space-y-3">
                            <div>
                                At Gobucks, we are committed to delivering exceptional cleaning services that meet your needs and exceed your expectations. When you choose us, you can expect:
                            </div>
                            <ul className="list-disc ml-6">
                                <li>Reliable and trustworthy service</li>
                                <li>Flexible scheduling to suit your needs</li>
                                <li>Competitive pricing with no hidden fees</li>
                                <li>Eco-friendly cleaning products and methods</li>
                            </ul>

                        </div>
                    </div>
                    <div>
                        <div className="text-left text-xl text-gray-500 my-2">
                            7. Our Leadership:
                        </div>
                        <div className="text-gray-700 ml-6">
                            Founded and led by Malith Hettiarachchige, Gobucks is built on
                            a foundation of trust, integrity, and a passion for cleanliness.
                        </div>
                    </div>
                    <div>
                        <div className="text-left text-xl text-gray-500 my-2">
                            8. Customer Satisfaction:
                        </div>
                        <div className="text-gray-700 ml-6">
                            Our top priority is customer satisfaction, and we strive to build
                            long-lasting relationships with our clients through consistent,
                            high-quality service.
                        </div>
                    </div>
                </div>

                {/* contact us for more information. add button */}
                <div className="mt-5">
                    <div className="text-center text-xl text-gray-500 my-4">
                        Contact us for more information.
                    </div>
                    <div className="text-center">
                        <button
                            type="button"
                            onClick={() => navigate("/contact-us")}
                            className="text-xs px-5 py-[0.7rem] cursor-pointer bg-primaryColor text-white duration-300 transition-all ease-in-out hover:text-secondaryColor hover:scale-105"
                        >
                            Contact Us
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
