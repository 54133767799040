import React from 'react'
import { SpecialOfferIf } from '../../../../types';
import { SpecialOfferCard } from '../../../components';

interface ViewSpecialOffersProps {
    specialOffers: SpecialOfferIf[];
    handleModalOpen: (type: string, data: SpecialOfferIf) => void;
}

const ViewSpecialOffers: React.FC<ViewSpecialOffersProps> = ({ specialOffers, handleModalOpen }) => {
    return (
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10'>
            <SpecialOfferCard data={specialOffers} isAdmin handleModalOpen={handleModalOpen} />
        </div>
    )
}

export default ViewSpecialOffers
