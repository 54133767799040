import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { AppProvider } from '../contexts/AppContext';
import PrivateRoute from './auth/PrivateRoute';
import { Error404Page, Login, Unauthorized } from './main';
import { About, CarpetCleaning, CleaningServices, CommercialCleaning, ContactUs, DomesticCleaning, EcoFriendlyCleaning, EndOfTenancyCleaning, Home, OneOffDeepCleaning, OvenCleaning, Prices, SpecialOffers } from './pages';
import { Dashboard, AdminSpecialOffers, Profile, Users, Pricing } from './admin';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const App: React.FC = () => {
  return (
    <AppProvider>
      <HashRouter>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<PrivateRoute element={<Home />} roles={['ADMIN', 'GUEST']} />} />
          <Route path="/contact-us" element={<PrivateRoute element={<ContactUs />} roles={['ADMIN', 'GUEST']} />} />
          <Route path="/admin-login" element={<Login />} />
          <Route path="/about" element={<PrivateRoute element={<About />} roles={['ADMIN', 'GUEST']} />} />
          <Route path="/special-offers" element={<PrivateRoute element={<SpecialOffers />} roles={['ADMIN', 'GUEST']} />} />
          <Route path="/prices" element={<PrivateRoute element={<Prices />} roles={['ADMIN', 'GUEST']} />} />
          <Route path="/cleaning-services" element={<PrivateRoute element={<CleaningServices />} roles={['ADMIN', 'GUEST']} />} />
          <Route path="/cleaning-services/domestic-cleaning" element={<PrivateRoute element={<DomesticCleaning />} roles={['ADMIN', 'GUEST']} />} />
          <Route path="/cleaning-services/carpet-cleaning" element={<PrivateRoute element={<CarpetCleaning />} roles={['ADMIN', 'GUEST']} />} />
          <Route path="/cleaning-services/commercial-cleaning" element={<PrivateRoute element={<CommercialCleaning />} roles={['ADMIN', 'GUEST']} />} />
          <Route path="/cleaning-services/eco-friendly-cleaning" element={<PrivateRoute element={<EcoFriendlyCleaning />} roles={['ADMIN', 'GUEST']} />} />
          <Route path="/cleaning-services/end-of-tenancy-cleaning" element={<PrivateRoute element={<EndOfTenancyCleaning />} roles={['ADMIN', 'GUEST']} />} />
          <Route path="/cleaning-services/one-off-deep-cleaning" element={<PrivateRoute element={<OneOffDeepCleaning />} roles={['ADMIN', 'GUEST']} />} />
          <Route path="/cleaning-services/oven-cleaning" element={<PrivateRoute element={<OvenCleaning />} roles={['ADMIN', 'GUEST']} />} />

          <Route path="/admin/dashboard" element={<PrivateRoute element={<Dashboard />} roles={['ADMIN']} />} />
          <Route path="/admin/special-offers" element={<PrivateRoute element={<AdminSpecialOffers />} roles={['ADMIN']} />} />
          <Route path="/admin/pricing" element={<PrivateRoute element={<Pricing />} roles={['ADMIN']} />} />
          <Route path="/admin/users" element={<PrivateRoute element={<Users />} roles={['ADMIN']} />} />
          <Route path="/admin/profile" element={<PrivateRoute element={<Profile />} roles={['ADMIN']} />} />

          {/* Common Routes */}
          <Route path="*" element={<Error404Page />} />
          <Route path="unauthorized" element={<Unauthorized />} />
        </Routes>
      </HashRouter>
    </AppProvider>
  );
};

export default App;
