import React from 'react'
import { ComingSoon, CommonHeader } from '../../components'

const Prices: React.FC = () => {
    return (
        <div>
            <CommonHeader
                title='Prices'
                bgImage='assets/images/our-services/oven-cleaning.jpg'
                mainPath={[{ name: 'Home', path: '/' }]}
            />
            <ComingSoon />
        </div>
    )
}

export default Prices
