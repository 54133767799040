import React, { useState } from "react";
import SideBar from "../side-bar";
import AdminNavBar from "../admin-navBar";
import adminNavigationConfig from "../../routes/adminNavigationConfig";

interface AdminLayoutProps {
    children: React.ReactNode;
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="bg-[#ececec81] min-h-screen">
            <AdminNavBar isOpen={isOpen} />
            <div className="flex">
                <SideBar isOpen={isOpen} toggleSidebar={toggleSidebar} adminNavItem={adminNavigationConfig} />
                <div
                    className={`flex-grow transition-all duration-300 ease-in-out pt-[calc(48px+1.5rem)] ${isOpen ? "ml-[4rem] sm:ml-[17rem]" : "ml-[4rem]"
                        }`}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AdminLayout;
