import React, { useEffect } from 'react'
import { BookAQuote, CommonHeader, MainTitle } from '../../../components'

const OvenCleaning: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='border-b-2'>
            <CommonHeader
                title='Oven Cleaning Services'
                bgImage='assets/images/our-services/oven-cleaning.jpg'
                mainPath={[
                    { name: 'Home', path: '/' },
                    { name: 'Cleaning Services', path: '/cleaning-services' }
                ]}
            />
            <div className="container mx-auto py-14 space-y-6">
                <MainTitle title='Oven Cleaning Services by GOBUCKS' />
                <div className="text-gray-700">
                    At GOBUCKS, we understand that a clean oven is essential for maintaining a hygienic kitchen and ensuring delicious, evenly cooked meals. Our professional oven cleaning services are designed to remove grease, grime, and baked-on residues, leaving your oven sparkling clean and ready to use. With GOBUCKS, you can trust that your oven will shine like new.
                    <img src="assets/images/our-services/oven-cleaning.jpg" alt="Oven Cleaning" className="w-full h-auto mt-5" />
                </div>

                <MainTitle title='Why Choose GOBUCKS for Oven Cleaning?' />
                <div className="text-gray-700">
                    <ul className="list-disc ml-5 space-y-3">
                        <li>Expert Techniques: Our team of experienced technicians is trained in the latest oven cleaning techniques and methods. We use specialized equipment and eco-friendly cleaning products to achieve exceptional results without causing damage to your oven.</li>
                        <li>Thorough Cleaning: We pay attention to every detail, ensuring all parts of your oven, including racks, trays, and door glass, are cleaned to perfection. We remove stubborn stains and grease buildup to restore your oven’s appearance and functionality.</li>
                        <li>Safe and Eco-Friendly Products: We care about your health and the environment. Our oven cleaning products are non-toxic and eco-friendly, providing a safe and healthy cooking environment for you and your family.</li>
                        <li>Convenient Service: We understand that your time is valuable. Our oven cleaning service is designed to be convenient and hassle-free, with flexible scheduling options to fit your busy lifestyle.</li>
                        <li>Satisfaction Guaranteed: Your satisfaction is our priority. If you’re not completely satisfied with our oven cleaning service, we’ll make it right to ensure you are happy with the results.</li>
                    </ul>
                </div>

                <MainTitle title='Our Oven Cleaning Process:' />
                <div className="text-gray-700">
                    <ol className="list-decimal list-inside mb-6 space-y-3">
                        <li>Assessment: Our technicians will assess the condition of your oven and discuss your specific cleaning needs.</li>
                        <li>Preparation: We’ll carefully protect surrounding surfaces and remove any removable parts from the oven, such as racks and trays.</li>
                        <li>Cleaning: Using our specialized equipment and eco-friendly cleaning products, we’ll clean all parts of your oven, including the interior, door glass, and exterior surfaces.</li>
                        <li>Rinsing and Drying: We’ll rinse the oven thoroughly to remove any residue and ensure it’s completely clean. We’ll then dry the oven and reassemble any removed parts.</li>
                        <li>Final Inspection: We conduct a final inspection to ensure your oven is spotless and ready to use.</li>
                    </ol>
                </div>

                <MainTitle title='How It Works:' />
                <div className="text-gray-700">
                    <ol className="list-decimal list-inside mb-6 space-y-3">
                        <li>Schedule Your Cleaning: Contact us to book your oven cleaning service at a time that’s convenient for you.</li>
                        <li>Professional Cleaning: Our skilled technicians will arrive on time and perform a thorough cleaning of your oven, using advanced techniques and eco-friendly products.</li>
                        <li>Enjoy Your Clean Oven: Sit back, relax, and enjoy cooking in a sparkling clean oven.</li>
                    </ol>
                </div>

                <div className="text-gray-700">
                    Experience the convenience and satisfaction of a professionally cleaned oven with GOBUCKS’ oven cleaning services. Contact us today to schedule your service and enjoy a cleaner, healthier kitchen.
                </div>
                <div className="flex justify-center flex-col items-center space-y-4">
                    <span className="text-gray-700 uppercase font-bold">Send us a message to book your oven cleaning service today!</span>
                    <BookAQuote bookQuoteType='send-message' serviceType='oven-cleaning' />
                </div>
            </div>
        </div>
    )
}

export default OvenCleaning
