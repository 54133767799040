import React from 'react'
import { AdminHeadTitle } from '../../components'

const Pricing: React.FC = () => {
    return (
        <div className='p-4'>
            <AdminHeadTitle
                title='Pricing'
                subtitle='Manage Pricing'
            />
        </div>
    )
}

export default Pricing
