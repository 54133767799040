interface ServiceOptions {
    id: number;
    label: string;
    value: string;
}

export const servicesOptionsArray: ServiceOptions[] = [
    {
        id: 1,
        label: 'Domestic Cleaning',
        value: 'domestic-cleaning'
    },
    {
        id: 2,
        label: 'Eco Friendly Cleaning Services',
        value: 'eco-friendly-cleaning'
    },
    {
        id: 3,
        label: 'Commercial Cleaning',
        value: 'commercial-cleaning'
    },
    {
        id: 4,
        label: 'One Off Deep Cleaning',
        value: 'one-off-deep-cleaning'
    },
    {
        id: 5,
        label: 'End of Tenancy Cleaning Services',
        value: 'end-of-tenancy-cleaning'
    },
    {
        id: 6,
        label: 'Carpet Cleaning Services',
        value: 'carpet-cleaning'
    },
    {
        id: 7,
        label: 'Oven Cleaning',
        value: 'oven-cleaning'
    }
]