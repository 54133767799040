import React from 'react'
import { AdminHeadTitle } from '../../components'
import adminNavigationConfig, { AdminNavigationItem } from "../../routes/adminNavigationConfig";
import { useNavigate } from 'react-router-dom';
const Dashboard: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className='p-4'>
            <AdminHeadTitle
                title='Dashboard'
                subtitle='Manage Dashboard'
            />
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8'>
                {adminNavigationConfig && adminNavigationConfig.map((item: AdminNavigationItem, index: number) => (
                    <div
                        key={index}
                        className='bg-white p-4 rounded-md shadow-md cursor-pointer hover:shadow-lg min-h-28'
                        onClick={() => navigate(item.path)}
                    >
                        <div className='flex items-center gap-2 h-full'>
                            <div className='bg-secondaryColor p-2 rounded-md'>
                                {item.icon}
                            </div>
                            <div>
                                <h1 className='text-lg font-semibold'>{item.title}</h1>
                                <p className='text-gray-500'>{item.subtitle}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Dashboard
